import { StringParam, ArrayParam, useQueryParams } from 'use-query-params'

import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { VerificationsTable } from './VerificationsTable/VerificationsTable'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'

export function VerificationsContainer() {
  const { applicationsService } = useServices()
  const { t } = useTranslation()
  useBreadcrumbs([
    {
      label: t('breadcrumb.verifications')
    }
  ])

  const [queryParams, setQueryParams] = useQueryParams(
    {
      email: StringParam,
      statuses: ArrayParam,
      configurationIds: ArrayParam,
      organisationIds: ArrayParam,
      validationRequestIds: ArrayParam,
      countryCodes: ArrayParam
    },
    {}
  )

  const [{ data, isLoading, totalResults, errorMessage }, { query, refresh, nextPage, previousPage }] = useQueryList(
    applicationsService.getValidationSubmissions,
    queryParams
  )
  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <VerificationsTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      query={query}
      refresh={refresh}
      nextPage={nextPage}
      previousPage={previousPage}
    />
  )
}

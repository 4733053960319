import { useTranslation } from 'react-i18next'
import { RoutePath } from '../../layout/Routes'
import { useBreadcrumbs } from '../../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { SlackInstall } from './Slack/SlackInstall'
import styles from './Integrations.module.scss'

export function Integrations() {
  const { t } = useTranslation()
  useBreadcrumbs([
    {
      label: t('breadcrumb.workplaceGiving'),
      path: RoutePath.WORKPLACE_GIVING
    },
    {
      label: t('breadcrumb.integrations')
    }
  ])

  return (
    <div className={styles.wrapper}>
      <SlackInstall />
    </div>
  )
}

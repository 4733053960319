const abilityKeys = [
  'viewApiKeys',
  'manageApiKeys',
  'viewDashboard',
  'viewDonations',
  'viewFundraisers',
  'manageFundraisers',
  'viewOpportunities',
  'manageOpportunities',
  'viewGrants',
  'manageGrantReviews',
  'manageGrantEvents',
  'manageDonationWallet',
  'viewUsers',
  'manageUsers',
  'manageOwners',
  'manageAdmins',
  'manageDevelopers',
  'manageMembers',
  'manageGrantReviewers',
  'viewCategories',
  'viewNonprofits',
  'viewVerifications'
] as const
export type AbilityKey = typeof abilityKeys[number]
export type DefaultRoleId = 'pd:member' | 'pd:developer' | 'pd:admin' | 'pd:owner' | 'pd:grants_reviewer'

const permissions = {
  // IAM Domain
  api_keys: ['read', 'write'],
  pd_dashboard: ['access'],
  users: ['read', 'write'],
  user_invite: ['write'],
  user_role_owner: ['write'],
  user_role_admin: ['write'],
  user_role_developer: ['write'],
  user_role_member: ['write'],
  user_role_grants_reviewer: ['write'],

  // Donation Domain
  donations: ['read'],

  // Validation Domain
  validation_submissions: ['read'],
  validation_requests: ['read'],
  agent_verifications: ['read'],

  // Categories Domain
  categories: ['read'],

  // Disbursement Domain

  // Organization Domain
  organizations: ['read'],

  // Partner Fundraisers
  partner_fundraiser: ['read', 'write'],

  // Partner Opportunities
  opportunities: ['read', 'write'],

  // Grants
  grants: ['read'],
  grants_candidate_review: ['write'],
  grants_admin_event: ['write'],

  // Donation Wallet
  donation_wallet: ['write']
} as const

type PermissionString<T extends Record<string, readonly string[]>, K extends keyof T = keyof T> = K extends string
  ? `${K}:${T[K][number]}`
  : never
export type Permission = PermissionString<typeof permissions>

export const abilityDefinitions: Record<AbilityKey, Permission[]> = {
  viewApiKeys: ['api_keys:read'],
  manageApiKeys: ['api_keys:write'],
  manageOwners: ['user_role_owner:write'],
  manageAdmins: ['user_role_admin:write'],
  manageDevelopers: ['user_role_developer:write'],
  manageMembers: ['user_role_member:write'],
  manageGrantReviewers: ['user_role_grants_reviewer:write'],
  viewDashboard: ['pd_dashboard:access'],
  viewDonations: ['donations:read'],
  viewFundraisers: ['partner_fundraiser:read'],
  manageFundraisers: ['partner_fundraiser:write'],
  viewOpportunities: ['opportunities:read'],
  manageOpportunities: ['opportunities:write'],
  viewGrants: ['grants:read'],
  manageGrantReviews: ['grants_candidate_review:write'],
  manageGrantEvents: ['grants_admin_event:write'],
  manageDonationWallet: ['donation_wallet:write'],
  viewUsers: ['users:read'],
  manageUsers: ['users:write'],
  viewCategories: ['categories:read'],
  viewNonprofits: ['organizations:read'],
  viewVerifications: ['validation_submissions:read']
}

import { useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { CategoryTable } from './CategoryTable/CategoryTable'
import { useBreadcrumbs } from '../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { useTranslation } from 'react-i18next'

export function CategoryContainer() {
  const { categoryService } = useServices()
  const { t } = useTranslation()

  const [{ data, isLoading, totalResults, errorMessage }, { query, nextPage, previousPage }] = useQueryList(
    categoryService.getCategoryList,
    {}
  )

  useBreadcrumbs([
    {
      label: t('breadcrumb.categories')
    }
  ])

  return (
    <CategoryTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      query={query}
    />
  )
}

import { Box, Grid, Paper } from '@material-ui/core'

import { KeyListTable } from './KeyListTable'
import { useBreadcrumbs } from '../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { useTranslation } from 'react-i18next'

export function Key() {
  const { t } = useTranslation()

  useBreadcrumbs([
    {
      label: t('breadcrumb.keys')
    }
  ])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box component={Paper}>
          <KeyListTable />
        </Box>
      </Grid>
    </Grid>
  )
}

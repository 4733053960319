import React, { useEffect, useState } from 'react'
import { SplitFactory, SplitClient } from '@splitsoftware/splitio-react'

import { EnvironmentOverlay } from './dashboard/layout/environmentOverlay/EnvironmentOverlay'
import { ToastContainer } from '@percent/partner-dashboard/containers/toast/ToastContainer'
import '@mantine/core/styles.css'
import './app.scss'
import { AuthRoutes } from './auth/routes/Routes'
import { AppProviders } from './providers/AppProviders/AppProviders'
import { AppRoutes } from './routes/Routes'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { splitSdkConfig } from '@percent/partner-dashboard/app/providers/SplitSdkProvider/SplitSdkProvider'

export function App() {
  const { isAuthorised, partner, user } = useAuthState()

  const [configKey, setConfigKey] = useState(user?.email ?? 'key')
  const [attributes, setAttributes] = useState<{ partner_id?: string }>({
    partner_id: partner?.id
  })

  useEffect(() => {
    if (user && user.id) {
      const key = user.id

      if (configKey !== key) {
        setConfigKey(key)
      }
    }

    if (partner) {
      if (attributes.partner_id !== partner.id) {
        setAttributes({ partner_id: partner.id })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, partner?.id])

  return (
    <SplitFactory config={splitSdkConfig}>
      <SplitClient splitKey={configKey} attributes={attributes} updateOnSdkTimedout>
        <AppProviders>
          <EnvironmentOverlay />
          <ToastContainer>{isAuthorised ? <AppRoutes /> : <AuthRoutes />}</ToastContainer>
        </AppProviders>
      </SplitClient>
    </SplitFactory>
  )
}

import { ButtonTextV2, Flex, Icon, Typography } from '@percent/lemonade'
import { DEFAULT_NOMINATION_LOCALES, LocaleSwitchHeaderProps, NominationLocale } from './LocaleSwitchHeader.types'
import styles from './LocaleSwitchHeader.module.scss'
import { useTranslation } from 'react-i18next'

export const LocaleSwitchHeader = ({
  selectedLocale,
  setSelectedLocale,
  originalLocale,
  translationAvailable
}: Readonly<LocaleSwitchHeaderProps>) => {
  const { t } = useTranslation()

  if (DEFAULT_NOMINATION_LOCALES.includes(originalLocale)) {
    return null
  }

  const languageName = new Intl.DisplayNames([NominationLocale.EN_US], { type: 'language' }).of(
    originalLocale.split('-')[0]
  )

  const switchTranslation = () => {
    // Always fallback to en-US
    setSelectedLocale(selectedLocale === originalLocale ? NominationLocale.EN_US : originalLocale)
  }

  return (
    <Flex align="center" pb={8} gap={12} data-testid="locale-switch-header">
      <Flex key={selectedLocale} className={styles.iconWrapper}>
        <Icon name="reward-stars" size="s" color={translationAvailable ? 'primary400' : 'gray600'} />
      </Flex>
      {translationAvailable ? (
        <Flex align="center" gap={8}>
          <Typography variant="default-medium" $color="primary400">
            {selectedLocale === originalLocale
              ? t('grants.nominations.localeSwitchHeader.automaticTranslationAvailable')
              : t('grants.nominations.localeSwitchHeader.automaticallyTranslatedFrom', { language: languageName })}
          </Typography>
          <ButtonTextV2
            variant="primary"
            size="compact-xs"
            onClick={switchTranslation}
            data-testid="locale-switch-button"
          >
            {t(
              selectedLocale === originalLocale
                ? 'grants.nominations.localeSwitchHeader.viewTranslation'
                : 'grants.nominations.localeSwitchHeader.viewOriginal'
            )}
          </ButtonTextV2>
        </Flex>
      ) : (
        <Typography variant="default-medium" $color="gray600">
          {t('grants.nominations.localeSwitchHeader.translationUnavailable', { language: languageName })}
        </Typography>
      )}
    </Flex>
  )
}

import { Typography } from '@percent/lemonade'
import styles from './integrationCard.module.scss'
import { IntegrationCardProps } from './integrationCard.types'
import { Fragment } from 'react'

export function IntegrationCard({ logo, name, description, children }: Readonly<IntegrationCardProps>) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>{typeof logo === 'string' ? <img src={logo} alt={name} /> : logo}</div>
      <div className={styles.details}>
        <Typography variant="h6" color="secondary" className={styles.name}>
          {name}
        </Typography>
        {!!description && (
          <Typography data-testid="tile-description" className={styles.description}>
            {description.split('\n').map((line, index) => (
              <Fragment key={index}>
                {index > 0 && <br />}
                <span>{line}</span>
              </Fragment>
            ))}
          </Typography>
        )}
      </div>
      <div>{children}</div>
    </div>
  )
}

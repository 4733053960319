import { useFormik } from 'formik'
import * as Yup from 'yup'
import { EventHandler, KeyboardEvent, useCallback } from 'react'
import { SearchAndFiltersProps } from '../SearchAndFilters.types'

export const useOrganisationSearch = ({ queryParams, setQueryParams }: SearchAndFiltersProps) => {
  const { values, setFieldValue, handleChange, submitForm, validateForm } = useFormik({
    initialValues: {
      organisation: queryParams.searchTerm
    },
    validateOnChange: false,
    validationSchema: () =>
      Yup.object().shape({
        organisation: Yup.string()
      }),
    onSubmit: ({ organisation }) => setQueryParams({ searchTerm: organisation?.trim() || undefined })
  })

  const handleClearOrganisationValue = () => {
    setFieldValue('organisation', '')
    submitForm()
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') submitForm()
      if (event.key === 'Escape') setFieldValue('organisation', '')
    },
    [setFieldValue, submitForm]
  )

  return {
    organisation: values.organisation,
    organisationSearch: {
      onOrganisationChange: handleChange,
      handleClearOrganisationValue,
      handleKeyPress,
      validateOrganisation: () => validateForm()
    }
  }
}

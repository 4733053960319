import { useTranslation } from 'react-i18next'

import styles from './Dashboard.module.scss'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { Heading, Text } from '@percent/lemonade'
import bannerHeartImg from '@percent/partner-dashboard/common/assets/images/banner-heart.png'
import bannerCoinImg from '@percent/partner-dashboard/common/assets/images/banner-coin.png'
import { useBreadcrumbs } from '../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'

export function Dashboard() {
  const { user, partner } = useAuthState()
  const { t } = useTranslation()
  useBreadcrumbs([
    {
      label: t('breadcrumb.dashboard')
    }
  ])

  return (
    <div className={styles.dashboardContainer} data-testid="dashboard-container">
      <div className={styles.contentGreetingWrapper} data-testid="greeting-section">
        <div className={styles.icon}>
          <span className={styles.wave} role="img" aria-label="Waving hand">
            👋
          </span>
        </div>
        <div className={styles.greetingContainer} data-testid="greeting-text">
          <Heading level="h2">
            {`${t('typography.dashboard.title')}${
              user?.preferredName || user?.fullName ? `, ${user?.preferredName || user?.fullName}` : ''
            }`}
          </Heading>
        </div>
      </div>
      <div className={styles.contentBannerWrapper} data-testid="banner-section">
        <div className={styles.leftColumn}>
          <img src={bannerHeartImg} alt="heart" className={styles.bannerHeart} />
        </div>
        <div className={styles.bannerText} data-testid="banner-text">
          <Heading level="h1" align="center">
            {t('typography.dashboard.bannerTitle')}
          </Heading>
          <Text size="large">{t('typography.dashboard.bannerSubtitle', { partnerName: partner?.name })}</Text>
        </div>
        <div className={styles.rightColumn}>
          <img src={bannerCoinImg} alt="coin" className={styles.bannerCoin} />
        </div>
      </div>
    </div>
  )
}

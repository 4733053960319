import { StringParam, useQueryParams } from 'use-query-params'
import { useEffect, useState } from 'react'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'

export function useGrantReview() {
  const { user } = useAuthState()
  const { userCan } = usePermissionCheck()
  const [queryParams, setQueryParams] = useQueryParams({ inReview: StringParam })
  const [isReviewOpen, setIsReviewOpen] = useState(queryParams.inReview === 'true')

  const handleCloseReview = () => {
    setQueryParams({ inReview: undefined })
  }

  const handleOpenReview = () => {
    setQueryParams({ inReview: 'true' })
  }

  useEffect(() => {
    setIsReviewOpen(queryParams.inReview === 'true')
  }, [queryParams.inReview])

  return {
    isReviewOpen,
    canManageGrantReviews: userCan('manageGrantReviews'),
    currentUserAccountId: user?.id,
    handleCloseReview,
    handleOpenReview
  }
}

import { Flex, Loader, Tab } from '@percent/lemonade'
import { GrantProgramCandidateCard } from './grantProgramCandidateCard/GrantProgramCandidateCard'
import { GrantProgramCandidateHeader } from './grantProgramCandidateHeader/GrantProgramCandidateHeader'
import { useState } from 'react'
import { GrantProgramCandidateReview } from './grantProgramCandidateReview/GrantProgramCandidateReview'
import styles from './GrantProgramCandidateDetailsContainer.module.scss'
import { GrantProgramCandidateHeaderTab } from './grantProgramCandidateHeader/GrantProgramCandidateHeader.types'
import { GrantProgramCandidateReviewsCard } from './grantProgramCandidateReviewsCard/GrantProgramCandidateReviewsCard'
import { useGrantProgramCandidateDetails } from './hooks/useGrantProgramCandidateDetails'
import { useGrantReview } from './hooks/useGrantCandidateReview'
import { GrantProgramCandidateNominationsCard } from './grantProgramCandidateNominationsCard/GrantProgramCandidateNominationsCard'
import { useBreadcrumbs } from '../../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { RoutePath } from '../../layout/Routes'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'

export function GrantProgramCandidateDetailsContainer() {
  const [activeTab, setActiveTab] = useState<GrantProgramCandidateHeaderTab>('organization')
  const { data, errors, refresh, isLoading } = useGrantProgramCandidateDetails()
  const { grantsService } = useServices()
  const { isReviewOpen, canManageGrantReviews, currentUserAccountId, handleCloseReview, handleOpenReview } =
    useGrantReview()
  const { state } = useLocation()
  const { t } = useTranslation()

  const { id: programId } = useParams<{ id: string }>()

  const [{ data: grantProgram }] = useQuery(grantsService.getGrantProgram, { programId })

  useBreadcrumbs([
    {
      label: t('breadcrumb.grants'),
      path: RoutePath.GRANT_PROGRAMS
    },
    {
      label: state?.organisationName || grantProgram?.name || '',
      path: generatePath(RoutePath.GRANT_PROGRAM_CANDIDATES, { id: programId })
    },
    {
      label: data?.candidate?.organisation?.name || ''
    }
  ])

  const canLeaveReview =
    canManageGrantReviews && !data.reviews?.some(r => r.reviewer.accountId === currentUserAccountId)

  if (isLoading) {
    return <Loader />
  }

  return (
    <Flex gap={16}>
      <Flex data-testid="grant-program-candidate-details-container" className={styles.detailsCtn}>
        <GrantProgramCandidateHeader
          candidate={data.candidate}
          isReviewOpen={canLeaveReview && isReviewOpen}
          onOpenReview={handleOpenReview}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          showReviewButton={canLeaveReview}
          refetchCandidate={refresh.candidate}
        />
        <Tab value={activeTab} index="organization">
          {/* TODO: We probably need to pass errors to each of the cards and handle it there (generic component maybe?) */}
          {!errors.candidateError && <GrantProgramCandidateCard candidate={data.candidate} />}
        </Tab>
        <Tab value={activeTab} index="nomination">
          {!errors.nominationsError && (
            <GrantProgramCandidateNominationsCard nominations={data.nominations.nominations} />
          )}
        </Tab>
        <Tab value={activeTab} index="review">
          {!errors.reviewsError && (
            <GrantProgramCandidateReviewsCard
              reviews={data.reviews}
              onOpenReview={handleOpenReview}
              currentUserAccountId={currentUserAccountId}
              canManageGrantReviews={canManageGrantReviews}
              showReviewButton={canLeaveReview}
            />
          )}
        </Tab>
      </Flex>
      {canLeaveReview && (
        <GrantProgramCandidateReview
          isReviewOpen={isReviewOpen}
          organisationName={data.candidate.organisation.name}
          onClose={handleCloseReview}
          refreshReviews={refresh.reviews}
        />
      )}
    </Flex>
  )
}

import { useEffect } from 'react'

import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { useBreadcrumbs } from '@percent/partner-dashboard/app/dashboard/layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { VerificationDetails } from './VerificationDetails/VerificationDetails'
import { RoutePath } from '../layout/Routes'
import { useTranslation } from 'react-i18next'

export function VerificationDetailsContainer({ match }: Readonly<{ match: { params: { verificationId: string } } }>) {
  const { verificationId } = match.params
  const { applicationsService, nonprofitsService } = useServices()
  const { t } = useTranslation()
  useBreadcrumbs([
    {
      label: t('breadcrumb.verifications'),
      path: RoutePath.VERIFICATIONS
    },
    {
      label: verificationId || ''
    }
  ])

  const [{ data, isLoading, errorMessage }] = useQuery(applicationsService.getValidationSubmission, {
    id: verificationId
  })

  const [{ data: configuration, isLoading: isLoadingConfiguration }, { query: queryConfiguration }] = useQuery(
    applicationsService.getValidationSubmissionConfiguration,
    {
      id: data?.configurationId
    },
    false,
    false
  )

  const [{ data: activityTags }] = useQuery(applicationsService.getActivityTags, {})

  const [{ data: validationSubmissionDocuments, isLoading: isLoadingValidationSubmissionDocuments }] = useQuery(
    applicationsService.getValidationSubmissionDocuments,
    {
      id: verificationId
    }
  )

  const [{ data: organisation, isLoading: isLoadingOrganisation }, { query }] = useQuery(
    nonprofitsService.getNonprofit,
    {
      id: data?.organisationId
    },
    !!data?.organisationId,
    false
  )

  useEffect(() => {
    data?.organisationId &&
      query({
        id: data?.organisationId
      })
  }, [data?.organisationId, query])

  useEffect(() => {
    data?.configurationId &&
      queryConfiguration({
        id: data?.configurationId
      })
  }, [data?.configurationId, queryConfiguration])

  return (
    <VerificationDetails
      data={data}
      activityTags={activityTags}
      configuration={configuration}
      organisation={organisation}
      isLoadingOrganisation={isLoadingOrganisation}
      isLoadingConfiguration={isLoadingConfiguration}
      isLoading={isLoading}
      errorMessage={errorMessage}
      documents={validationSubmissionDocuments || []}
      isLoadingValidationSubmissionDocuments={isLoadingValidationSubmissionDocuments}
    />
  )
}

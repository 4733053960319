import React, { useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'

import styles from './MyAccount.module.scss'
import { useTranslation } from 'react-i18next'
import { Button } from '@percent/lemonade'
import { ReactComponent as NothingHere } from '@percent/partner-dashboard/common/assets/images/nothing_here.svg'

import { ChangeTwoFactorDeviceContainer } from './ChangeTwoFactorDevice/ChangeTwoFactorDeviceContainer'
import { Switch } from '@percent/partner-dashboard/common/components/switch/Switch'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { DisableTwoFactorDeviceContainer } from './DisableTwoFactorDevice/DisableTwoFactorDeviceContainer'
import { useBreadcrumbs } from '../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'

export function MyAccount() {
  const { t } = useTranslation()
  const [isChangeTwoFactorDeviceModalOpened, setIsChangeTwoFactorDeviceModalOpened] = useState(false)
  const [isDisableTwoFactorDeviceModalOpened, setIsDisableTwoFactorDeviceModalOpened] = useState(false)
  const { user } = useAuthState()
  const [checked, setChecked] = React.useState(!!user?.twoFaSecretVerifiedAt)

  useBreadcrumbs([
    {
      label: t('breadcrumb.myAccount')
    }
  ])
  const handleChange = () => {
    if (checked === false) {
      setIsChangeTwoFactorDeviceModalOpened(true)
    }

    if (checked === true) {
      setIsDisableTwoFactorDeviceModalOpened(true)
    }
  }

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.container}>
      {user?.ssoUser ? (
        <div className={styles.infoContainer}>
          <div className={styles.nothingHere}>
            <NothingHere />
            <span className={styles.header}>{t('typography.nothingHere')}</span>
            <span className={styles.text}>{t('typography.ssoManagedProvider')}</span>
          </div>
        </div>
      ) : (
        <>
          <Grid item xs={12} className={styles.title}>
            <Typography variant="h6">{t('typography.accountSettings')}</Typography>
          </Grid>
          <div className={styles.optionContainer}>
            <div className={styles.textContainer}>
              <p className={styles.text}>{t('typography.2faOption')}</p>
              <p className={styles.subtext}>{t('typography.2faSubtext')}</p>
            </div>
            <div className={styles.buttonContainer}>
              {checked && (
                <Button variant="secondary" onPress={() => setIsChangeTwoFactorDeviceModalOpened(true)}>
                  {t('button.change2faDevice')}
                </Button>
              )}
              <Typography variant="body1">{t(checked ? '2fa.enable' : '2fa.disable')}</Typography>
              <Switch checked={checked} onChange={handleChange} />
            </div>
          </div>
          <ChangeTwoFactorDeviceContainer
            isOpened={isChangeTwoFactorDeviceModalOpened}
            onClose={() => setIsChangeTwoFactorDeviceModalOpened(false)}
          />
          <DisableTwoFactorDeviceContainer
            isOpened={isDisableTwoFactorDeviceModalOpened}
            onClose={() => setIsDisableTwoFactorDeviceModalOpened(false)}
            setChecked={setChecked}
          />
        </>
      )}
    </Grid>
  )
}

import { Accordion, Icon } from '@percent/lemonade'
import styles from './CustomAccordion.module.scss'
import { CustomAccordionProps } from './CustomAccordion.types'

export function CustomAccordion({
  children,
  variant = 'default',
  chevron = <Icon name="chevron-down" />,
  classNames = {
    item: styles.accordionItem,
    control: styles.accordionControl,
    label: styles.accordionLabel,
    content: styles.accordionContent,
    chevron: styles.accordionChevron
  },
  ...props
}: CustomAccordionProps) {
  return (
    <Accordion variant={variant} chevron={chevron} classNames={classNames} {...props}>
      {children}
    </Accordion>
  )
}

export default CustomAccordion

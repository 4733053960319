import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { DonationContainer } from '../donation/DonationContainer'
import { Dashboard } from '../dashboard/Dashboard'
import { Key } from '../key/Key'
import { NonprofitsContainer } from '../nonprofits/NonprofitsContainer'
import { CategoryContainer } from '../category/CategoryContainer'
import { CategoryNonprofitsTable } from '../category/categoryNonprofitsTable/CategoryNonprofitsTable'
import { MyAccount } from '../myAccount/MyAccount'
import { UsersContainer } from '../users/UsersContainer'
import { VerificationsContainer } from '../verifications/VerificatonsContainer'
import { VerificationDetailsContainer } from '../verifications/VerificationDetailsContainer'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { EmployeesContainer } from '../workplaceGiving/Employees/EmployeesContainer'
import { useAuthState, useFeatureFlag } from '@percent/partner-dashboard/common/hooks'
import { FundraisersContainer } from '../workplaceGiving/Fundraisers/FundraisersContainer'
import { VolunteeringContainer } from '../workplaceGiving/Volunteering/VolunteeringContainer'
import { VolunteeringSessionsOpportunityContainer } from '../workplaceGiving/Volunteering/VolunteeringSessionsOpportunityContainer/VolunteeringSessionsOpportunityContainer'
import { BulkWalletWizard } from '../workplaceGiving/Employees/BulkWalletWizard/BulkWalletWizard'
import { GrantProgramsContainer } from '../grants/GrantProgramsContainer'
import { GrantProgramCandidatesContainer } from '../grants/grantProgramCandidates/GrantProgramCandidatesContainer'
import { GrantProgramCandidateDetailsContainer } from '../grants/grantProgramCandidateDetails/GrantProgramCandidateDetailsContainer'
import { Integrations } from '../workplaceGiving/Integrations/Integrations'

export enum RoutePath {
  DASHBOARD = '/dashboard',
  APPLICATIONS = '/applications',
  APPLICATIONS_ID = '/applications/:applicationId',
  MY_ACCOUNT = '/my-account',
  KEYS = '/keys',
  DONATIONS = '/donations',
  NONPROFITS = '/nonprofits',
  USERS = '/users',
  CATEGORIES = '/categories',
  CATEGORIES_ID = '/categories/:categoryId',
  VERIFICATIONS = '/verifications',
  VERIFICATIONS_ID = '/verifications/:verificationId',
  APPLICATIONS_ID_TO_VERIFICATIONS_ID = '/verifications/:applicationId',
  GRANT_PROGRAMS = '/grant-programs',
  GRANT_PROGRAM = '/grant-programs/:id',
  GRANT_PROGRAM_CANDIDATES = '/grant-programs/:id/applications',
  GRANT_PROGRAM_CANDIDATE = '/grant-programs/:id/applications/:candidateId',
  WORKPLACE_GIVING = '/workplace-giving',
  WORKPLACE_GIVING_INTEGRATIONS = '/workplace-giving/integrations',
  WORKPLACE_GIVING_EMPLOYEES = '/workplace-giving/employees',
  WORKPLACE_GIVING_EMPLOYEES_BULK_WALLET_WIZARD = '/workplace-giving/employees/bulk-wallet-wizard',
  WORKPLACE_GIVING_FUNDRAISERS = '/workplace-giving/fundraisers',
  WORKPLACE_GIVING_VOLUNTEERING = '/workplace-giving/volunteering',
  WORKPLACE_GIVING_VOLUNTEERING_ID = '/workplace-giving/volunteering/:seriesOpportunityId'
}

export function Routes() {
  const { userCan } = usePermissionCheck()
  const { partner } = useAuthState()
  const { slackInstallFeatureFlag } = useFeatureFlag()

  const hasWorkplaceGiving = partner?.scopes?.some(data => data.scopeName.includes('workplace_giving'))
  const hasFundraisers = partner?.scopes?.some(data => data.scopeName.includes('donation_link'))
  const hasVolunteering = partner?.scopes?.some(data => data.scopeName.includes('volunteering'))
  const hasDonationWallet = partner?.scopes?.some(data => data.scopeName.includes('donation_wallet'))

  return (
    <Switch>
      <Route path={RoutePath.MY_ACCOUNT} exact component={MyAccount} />
      <Route path={RoutePath.DASHBOARD}>
        <Dashboard />
      </Route>
      <Route path={RoutePath.KEYS}>
        <Key />
      </Route>
      {userCan('viewDonations') && (
        <Route path={RoutePath.DONATIONS}>
          <DonationContainer />
        </Route>
      )}
      {userCan('viewNonprofits') && (
        <Route path={RoutePath.NONPROFITS}>
          <NonprofitsContainer />
        </Route>
      )}
      {userCan('viewGrants') && <Route exact path={RoutePath.GRANT_PROGRAMS} component={GrantProgramsContainer} />}
      {userCan('viewGrants') && (
        <Route exact path={RoutePath.GRANT_PROGRAM_CANDIDATES} component={GrantProgramCandidatesContainer} />
      )}
      {userCan('viewGrants') && (
        <Route exact path={RoutePath.GRANT_PROGRAM_CANDIDATE} component={GrantProgramCandidateDetailsContainer} />
      )}
      {userCan('viewUsers') && (
        <Route path={RoutePath.USERS}>
          <UsersContainer />
        </Route>
      )}
      {userCan('viewCategories') && <Route exact path={RoutePath.CATEGORIES} component={CategoryContainer} />}
      {userCan('viewCategories') && <Route exact path={RoutePath.CATEGORIES_ID} component={CategoryNonprofitsTable} />}
      {userCan('viewVerifications') && (
        <Route exact path={RoutePath.VERIFICATIONS} component={VerificationsContainer} />
      )}
      {userCan('viewVerifications') && (
        <Route exact path={RoutePath.VERIFICATIONS_ID} component={VerificationDetailsContainer} />
      )}
      {userCan('viewVerifications') && (
        <Route exact path={RoutePath.APPLICATIONS}>
          <Redirect to={RoutePath.VERIFICATIONS} />
        </Route>
      )}
      {userCan('viewVerifications') && (
        <Redirect from={RoutePath.APPLICATIONS_ID} to={RoutePath.APPLICATIONS_ID_TO_VERIFICATIONS_ID} />
      )}
      {slackInstallFeatureFlag && (
        <Route exact path={RoutePath.WORKPLACE_GIVING_INTEGRATIONS} component={Integrations} />
      )}
      {hasWorkplaceGiving && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route exact path={RoutePath.WORKPLACE_GIVING_EMPLOYEES} component={EmployeesContainer} />
      )}
      {userCan('manageDonationWallet') && hasDonationWallet && (
        <Route path={RoutePath.WORKPLACE_GIVING_EMPLOYEES_BULK_WALLET_WIZARD} component={BulkWalletWizard} />
      )}
      {hasFundraisers && userCan('viewFundraisers') && (
        <Route exact path={RoutePath.WORKPLACE_GIVING_FUNDRAISERS} component={FundraisersContainer} />
      )}
      {hasVolunteering && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route exact path={RoutePath.WORKPLACE_GIVING_VOLUNTEERING} component={VolunteeringContainer} />
      )}
      {hasVolunteering && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route
          exact
          path={RoutePath.WORKPLACE_GIVING_VOLUNTEERING_ID}
          component={VolunteeringSessionsOpportunityContainer}
        />
      )}
      {hasWorkplaceGiving && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route exact path={RoutePath.WORKPLACE_GIVING}>
          <Redirect to={RoutePath.WORKPLACE_GIVING_EMPLOYEES} />
        </Route>
      )}
      <Redirect from="*" to={RoutePath.DASHBOARD} />
    </Switch>
  )
}

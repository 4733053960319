import { Menu } from '@percent/lemonade'
import { FundraisersMenuProps } from './FundraisersMenu.types'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@percent/partner-dashboard/common/hooks'

export const FundraisersMenu = ({
  fundraiser,
  fundraiserUrl,
  openFundraiserModal,
  userCan,
  openFeaturedFundraiserModal,
  openRemoveFeaturedFundraiserModal
}: FundraisersMenuProps) => {
  const { t } = useTranslation()

  const menuItems = [
    { key: 'view', label: t('dialog.fundraisers.view') },
    ...(userCan('manageFundraisers') && fundraiser.status !== 'closed'
      ? [
          {
            key: 'close',
            label: t('dialog.fundraisers.close')
          },
          {
            key: fundraiser.featured ? 'removeFeaturedFundraiser' : 'featureFundraiser',
            label: t(fundraiser.featured ? 'dialog.fundraisers.remove' : 'dialog.fundraisers.feature.title')
          }
        ]
      : [])
  ]

  const handleMenuSelect = (actionKey: string) => {
    switch (actionKey) {
      case 'view':
        window.open(fundraiserUrl, '_blank', 'noopener noreferrer')
        break
      case 'close':
        openFundraiserModal(fundraiser)
        break
      case 'featureFundraiser':
        openFeaturedFundraiserModal({ id: fundraiser.id, title: fundraiser.page.title })
        break
      case 'removeFeaturedFundraiser':
        openRemoveFeaturedFundraiserModal({
          featuredItemId: fundraiser.featured?.featuredItemId,
          title: fundraiser.page.title
        })
        break
      default:
        console.warn(`Unhandled action: ${actionKey}`)
    }
  }

  return (
    <Menu
      title={t('button.more')}
      sections={[
        {
          items: menuItems
        }
      ]}
      onSelect={handleMenuSelect}
    />
  )
}

import { useTranslation } from 'react-i18next'

import { Button, ButtonText, Heading, Tooltip } from '@percent/lemonade'
import styles from './WizardHeader.module.scss'
import { WizardHeaderProps } from './WizardHeader.types'

export function WizardHeader({
  title,
  onCancel,
  onPublish,
  isPublishDisabled,
  isLoading,
  publishBtnCopy,
  toolTipCopy
}: Readonly<WizardHeaderProps>) {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <Heading level="h3">{title}</Heading>
      <div className={styles.buttonsWrapper}>
        <ButtonText onPress={onCancel} data-testid="cancel-button">
          {t('button.cancel')}
        </ButtonText>
        {isPublishDisabled && !isLoading ? (
          <Tooltip content={toolTipCopy}>
            <div data-testid="wrapper-disabled-confirm-button">
              <Button
                size="small"
                onPress={onPublish}
                disabled={isPublishDisabled}
                isDisabled={isPublishDisabled}
                loading={isLoading}
                data-testid="add-funds-btn"
              >
                {publishBtnCopy}
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            size="small"
            onPress={onPublish}
            disabled={isPublishDisabled}
            isDisabled={isPublishDisabled}
            loading={isLoading}
            data-testid="add-funds-btn"
          >
            {publishBtnCopy}
          </Button>
        )}
      </div>
    </div>
  )
}

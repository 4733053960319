import { Accordion as MantineAccordion } from '@mantine/core'
import { AccordionProps } from './accordion.types'

export const Accordion = <Multiple extends boolean = false>(
  props: AccordionProps<Multiple>
) => <MantineAccordion {...props} />

Accordion.Item = MantineAccordion.Item
Accordion.Control = MantineAccordion.Control
Accordion.Panel = MantineAccordion.Panel

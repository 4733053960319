import { useMutation } from '@percent/partner-dashboard/common/hooks/useMutation/useMutation'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { CandidateStateEvent } from '@percent/partner-dashboard/services/grants/grantsService.types'
import { useDebounce } from 'usehooks-ts'

import { useState } from 'react'
import { useParams } from 'react-router-dom'

const EVENT_PROPAGATION_DELAY = 1000

export const useAdminActions = (organisationName: string, refetch: () => void) => {
  const [selectedEvent, setSelectedEvent] = useState<CandidateStateEvent>()
  const [adminActionStatus, setAdminActionStatus] = useState<'success' | 'error'>()

  const { id: programId, candidateId } = useParams<{ id: string; candidateId: string }>()
  const { grantsService } = useServices()

  const [{ isLoading }, { apiFunc: triggerAdminAction }] = useMutation(
    grantsService.changeGrantProgramCandidateState,
    () => setAdminActionStatus('success'),
    () => setAdminActionStatus('error')
  )

  const debouncedIsLoading = useDebounce(isLoading, EVENT_PROPAGATION_DELAY)

  const handleSubmit = () => {
    if (!selectedEvent) return

    triggerAdminAction({
      programId,
      candidateId,
      event: selectedEvent
    })
  }

  const onClose = () => {
    setSelectedEvent(undefined)
    setAdminActionStatus(undefined)
  }

  const onSuccessClose = () => {
    refetch()
    onClose()
  }

  const adminActionModalProps = {
    onClose,
    onSuccessClose,
    // Suggested by BE as it might take a while for the state to update
    isLoading: debouncedIsLoading,
    organisationName,
    onSubmit: handleSubmit,
    success: adminActionStatus === 'success',
    error: adminActionStatus === 'error'
  }

  return {
    selectedEvent,
    events: {
      shortlist: () => setSelectedEvent(CandidateStateEvent.SHORTLIST),
      reject: () => setSelectedEvent(CandidateStateEvent.REJECT)
    },
    adminActionModalProps
  }
}

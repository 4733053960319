export enum NominationLocale {
  EN_US = 'en-US',
  EN_GB = 'en-GB'
}

export const DEFAULT_NOMINATION_LOCALES: string[] = [NominationLocale.EN_US, NominationLocale.EN_GB]

export type LocaleSwitchHeaderProps = {
  selectedLocale: string
  setSelectedLocale: (locale: string) => void
  originalLocale: string
  translationAvailable: boolean
}

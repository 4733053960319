import { useContext, useEffect } from 'react'

import { BreadcrumbsContext } from '../BreadcrumbsContext'
import { Breadcrumb } from '../BreadcrumbsContext.types'

export const useBreadcrumbs = (breadcrumbs?: Breadcrumb[]) => {
  const context = useContext(BreadcrumbsContext)

  if (context === undefined) {
    throw new Error('useBreadcrumbsData must be used within an BreadcrumbsContextController')
  }

  useEffect(() => {
    if (!breadcrumbs) {
      return
    }
    context.setBreadcrumbs(breadcrumbs)
  }, [JSON.stringify(breadcrumbs)])

  return { breadcrumb: context.breadcrumbs }
}

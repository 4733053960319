import { useTranslation } from 'react-i18next'
import styles from '../GrantProgramCandidateDetailsContainer.module.scss'
import { Badge, CardContent, CardDashboard, FlagAvatar, Flex } from '@percent/lemonade'
import { GrantProgramCandidateCardProps } from './GrantProgramCandidateCard.types'

export function GrantProgramCandidateCard({ candidate }: GrantProgramCandidateCardProps) {
  const { t } = useTranslation()
  const { organisation } = candidate

  const valueOrNotAvailableString = (value?: string | null) => value || t('typography.notAvailable')

  return (
    <Flex direction="column" data-testid="grant-program-candidate-card" className={styles.cardCtn}>
      <CardDashboard title={t('grants.organizationDetails.title')} data-testid="organization-details-card">
        <CardContent title={t('grants.organizationDetails.organization')} testId="organization-name">
          {organisation.name}
        </CardContent>
        <CardContent
          title={t('grants.organizationDetails.verificationStatus')}
          testId="organization-verification-status"
        >
          <Badge icon="check-badge" variant="positive">
            {t('grants.organizationDetails.verificationStatus.verified')}
          </Badge>
        </CardContent>
        <CardContent title={t('grants.organizationDetails.website')} testId="organization-website">
          {organisation.website ? (
            <a href={organisation.website} target="_blank" rel="noopener noreferrer">
              {organisation.website}
            </a>
          ) : (
            t('typography.notAvailable')
          )}
        </CardContent>
        <CardContent title={t('grants.organizationDetails.email')} testId="organization-email">
          {organisation.email ? (
            <a href={`mailto:${organisation.email}`}>{organisation.email}</a>
          ) : (
            t('typography.notAvailable')
          )}
        </CardContent>
        <CardContent title={t('grants.organizationDetails.country')} testId="organization-country">
          <Flex align="center" gap={8}>
            <FlagAvatar code3={organisation.country} />
            <span data-testid="organization-country-code">{organisation.country}</span>
          </Flex>
        </CardContent>
        <CardContent title={t('grants.organizationDetails.address')} testId="organization-address">
          {valueOrNotAvailableString(organisation.address)}
        </CardContent>
        <CardContent title={t('grants.organizationDetails.registryId')} testId="organization-registry-id">
          {valueOrNotAvailableString(organisation.registryId)}
        </CardContent>
      </CardDashboard>
    </Flex>
  )
}

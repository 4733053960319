import { AxiosInstance } from 'axios'
import {
  GetProgramParams,
  ChangeGrantProgramCandidateStateParams,
  GetCandidatesParams,
  GetGrantCandidateParams,
  GetGrantProgramReviewerFormParams,
  GrantProgram,
  GrantProgramCandidateNominationsResponse,
  GrantProgramCandidateReviewsResponse,
  GrantProgramReviewerFormResponse,
  SubmitGrantProgramReviewerFormParams,
  GrantProgramCandidateResponse,
  GrantProgramsResponse,
  GrantProgramCandidatesResponse,
  GrantProgramCandidate
} from './grantsService.types'
import { apiPaths } from '../apiPaths'
import { PBPResponse } from '../types/pbpResponses.types'

type grantsServiceCapabilities = {
  percentClient: AxiosInstance
}

export const grantsService = ({ percentClient }: grantsServiceCapabilities) => ({
  getGrantPrograms: () => percentClient.get<GrantProgramsResponse>(`${apiPaths.v1IamGrantPrograms}`),
  getGrantProgram: ({ programId }: GetProgramParams) =>
    percentClient.get<GrantProgram>(`${apiPaths.v1IamGrantPrograms}/${programId}`),
  getGrantCandidates: (params: GetCandidatesParams) => {
    const { programId, ...queryParams } = params
    return percentClient.get<GrantProgramCandidatesResponse>(`${apiPaths.v1IamGrantPrograms}/${programId}/candidates`, {
      params: queryParams
    })
  },
  getGrantCandidate: ({ programId, candidateId }: GetGrantCandidateParams) =>
    percentClient.get<PBPResponse<GrantProgramCandidateResponse>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/candidates/${candidateId}`
    ),
  getGrantCandidateNominations: ({ programId, candidateId }: GetGrantCandidateParams) =>
    percentClient.get<PBPResponse<GrantProgramCandidateNominationsResponse>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/candidates/${candidateId}/nominations`
    ),
  getGrantCandidateReviews: ({ programId, candidateId }: GetGrantCandidateParams) =>
    percentClient.get<PBPResponse<GrantProgramCandidateReviewsResponse>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/candidates/${candidateId}/reviews`
    ),
  getGrantProgramReviewerForm: ({ programId }: GetGrantProgramReviewerFormParams) =>
    percentClient.get<PBPResponse<GrantProgramReviewerFormResponse>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/reviewer-form`
    ),
  submitGrantProgramReviewerForm: ({ programId, candidateId, review }: SubmitGrantProgramReviewerFormParams) =>
    percentClient.post<PBPResponse<GrantProgramReviewerFormResponse>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/candidates/${candidateId}/reviews`,
      { review }
    ),
  changeGrantProgramCandidateState: ({ programId, candidateId, event }: ChangeGrantProgramCandidateStateParams) =>
    percentClient.post<PBPResponse<GrantProgramCandidate>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/candidates/${candidateId}/events`,
      { type: event }
    )
})

import { Flex } from '@percent/lemonade'
import { GrantProgramCandidateNominationsCardProps } from './GrantProgramCandidateNominationsCard.types'
import { GrantProgramCandidateNomination } from './grantProgramCandidateNomination/GrantProgramCandidateNomination'

import styles from '../GrantProgramCandidateDetailsContainer.module.scss'

export function GrantProgramCandidateNominationsCard({
  nominations
}: Readonly<GrantProgramCandidateNominationsCardProps>) {
  return (
    <Flex direction="column" data-testid="grant-program-candidate-nomination-card" className={styles.cardCtn} gap={16}>
      {nominations.map((nomination, index) => (
        <GrantProgramCandidateNomination key={nomination.id} nomination={nomination} index={index + 1} />
      ))}
    </Flex>
  )
}

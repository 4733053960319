import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { StringParam, useQueryParams } from 'use-query-params'
import { EmployeesTable } from './EmployeesTable/EmployeesTable'
import { useBreadcrumbs } from '../../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '../../layout/Routes'

export function EmployeesContainer() {
  const { workplaceGivingService } = useServices()
  const { t } = useTranslation()

  useBreadcrumbs([
    {
      label: t('breadcrumb.workplaceGiving'),
      path: RoutePath.WORKPLACE_GIVING
    },
    {
      label: t('breadcrumb.employees')
    }
  ])

  const [queryParams, setQueryParams] = useQueryParams(
    {
      filter: StringParam
    },
    {}
  )

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    workplaceGivingService.getEmployeesList,
    queryParams
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <EmployeesTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}

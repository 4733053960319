import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { StringParam, useQueryParams } from 'use-query-params'
import { FundraisersTable } from './FundraisersTable/FundraisersTable'
import { GetFundraisersListProps } from '@percent/partner-dashboard/services/fundraisers/fundraisersService.types'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { PartnerConfigProvider } from '@percent/partner-dashboard/context/config/PartnerConfigProvider'
import { PartnerConfigKey } from '@percent/partner-dashboard/context/config/PartnerConfigProvider.types'
import { RoutePath } from '../../layout/Routes'

export function FundraisersContainer() {
  const { fundraisersService } = useServices()
  const { t } = useTranslation()
  useBreadcrumbs([
    {
      label: t('breadcrumb.workplaceGiving'),
      path: RoutePath.WORKPLACE_GIVING
    },
    {
      label: t('breadcrumb.fundraisers')
    }
  ])

  const [queryParams, setQueryParams] = useQueryParams(
    {
      status: StringParam,
      query: StringParam
    },
    {}
  )

  const listParams: GetFundraisersListProps = {
    ...queryParams,
    status: queryParams?.status
  }

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    fundraisersService.getFundraisersList,
    listParams
  )

  useDidMountEffect(() => query(listParams), [queryParams, query])

  return (
    <PartnerConfigProvider partnerConfigKey={PartnerConfigKey.PREFERRED_COUNTRIES}>
      <FundraisersTable
        data={data}
        isLoading={isLoading}
        totalResults={totalResults}
        errorMessage={errorMessage}
        nextPage={nextPage}
        previousPage={previousPage}
        query={query}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        refresh={refresh}
      />
    </PartnerConfigProvider>
  )
}

import { TableCell, TableRow } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { EllipsisTableCell, ErrorView, Table } from '@percent/partner-dashboard/common/components'
import { useQuery, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { CategoryNonprofitsTableItemTypes } from '@percent/partner-dashboard/services/category/category.types'
import { ReactComponent as PlaceholderImage } from '@percent/partner-dashboard/common/assets/images/placeholder-image.svg'
import { useBreadcrumbs } from '@percent/partner-dashboard/app/dashboard/layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { TableCellEllipsisValue } from '@percent/partner-dashboard/common/components/tableCellEllipsisValue/TableCellEllipsisValue'
import { Avatar, Copy } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '../../layout/Routes'

export function CategoryNonprofitsTable() {
  const { t } = useTranslation()
  const { categoryId } = useParams<{ categoryId: string }>()
  const { categoryService } = useServices()
  const [{ data, isLoading, totalResults, errorMessage }, { nextPage, previousPage }] = useQueryList(
    categoryService.getCategoryNonprofitsList,
    {
      category: [categoryId]
    }
  )

  const [{ data: getCategoryData, isLoading: getCategoryIsLoading, errorMessage: getCategoryErrorMsg }] = useQuery(
    categoryService.getCategory,
    {
      id: categoryId
    }
  )

  useBreadcrumbs([
    {
      label: t('breadcrumb.categories'),
      path: RoutePath.CATEGORIES
    },
    {
      label: categoryId || ''
    }
  ])

  if (errorMessage || getCategoryErrorMsg) {
    return <ErrorView errorMessage={errorMessage || getCategoryErrorMsg} />
  }

  const columns = [
    { id: 'header.logo', isSortable: false },
    { id: 'header.id', isSortable: false },
    { id: 'header.name', isSortable: false, props: { align: 'right' } }
  ]

  return (
    <Table
      data={data}
      title={getCategoryData?.name}
      isLoading={isLoading || getCategoryIsLoading}
      totalResults={totalResults}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={columns}
      emptyTableText={t('typography.noCategoryNonprofitsTable')}
      emptyTableImageType="tick"
      handleRequestSort={() => {
        // do nothing
      }}
      orderBy=""
    >
      {data?.map(({ id, logo, displayName }: CategoryNonprofitsTableItemTypes, index: number) => (
        <TableRow key={`donation-list-${id}`}>
          <TableCell>
            {logo ? <Avatar image={logo} alt={`${displayName} logo`} size="medium" /> : <PlaceholderImage />}
          </TableCell>
          <TableCell>
            <Copy message={t('typography.copyId')} text={id}>
              <TableCellEllipsisValue id={id} ellipsis={false} />
            </Copy>
          </TableCell>
          <TableCell align="right">{displayName}</TableCell>
          <EllipsisTableCell rawJsonData={data[index]} />
        </TableRow>
      ))}
    </Table>
  )
}

import { Flex, ButtonText, Tabs, theme, Heading, Avatar, Badge, SplitButton } from '@percent/lemonade'
import {
  GrantProgramCandidateHeaderProps,
  GrantProgramCandidateHeaderTab,
  GrantProgramCandidateHeaderTabs
} from './GrantProgramCandidateHeader.types'

import styles from './GrantProgramCandidateHeader.module.scss'
import { useTranslation } from 'react-i18next'
import currentStateMapping from '../../currentStateMapping/currentStateMapping'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { useAdminActions } from './hooks/useAdminActions'
import { RejectOrganisationModal } from './rejectOrganisationModal/RejectOrganisationModal'
import { CandidateStateEvent } from '@percent/partner-dashboard/services/grants/grantsService.types'
import { ShortlistOrganisationModal } from './shortlistOrganisationModal/ShortlistOrganisationModal'

export function GrantProgramCandidateHeader({
  candidate,
  onOpenReview,
  isReviewOpen,
  activeTab,
  setActiveTab,
  showReviewButton,
  refetchCandidate
}: Readonly<GrantProgramCandidateHeaderProps>) {
  const { t } = useTranslation()
  const { userCan } = usePermissionCheck()
  const canManageGrantEvents = userCan('manageGrantEvents')

  const { organisation, currentState } = candidate

  const { selectedEvent, events, adminActionModalProps } = useAdminActions(organisation.name, refetchCandidate)

  const stateConfig = currentStateMapping[currentState]
  const showUpdateEventsButton = !showReviewButton && canManageGrantEvents && currentState === 'nominated'

  const tabs: GrantProgramCandidateHeaderTabs = [
    {
      label: t('grants.candidateDetails.header.organizationTab'),
      key: 'organization'
    },
    {
      label: t('grants.candidateDetails.header.nominationTab'),
      key: 'nomination'
    },
    {
      label: t('grants.candidateDetails.header.reviewsTab'),
      key: 'review'
    }
  ]

  const handleCLickSplitButtonMenu = (key: string) => {
    if (key === 'admin-reject-action') {
      events.reject()
    }
  }

  return (
    <Flex direction="column" className={styles.header} data-testid="grant-program-candidate-header">
      <Flex justify="space-between" align="center" m={theme.sizes['m']} className={styles.heading}>
        <Flex align="center" gap={theme.sizes['s']}>
          <Avatar image={organisation.logo} alt={organisation.name} size="medium" />
          <Flex gap={theme.sizes['xs']} align="center">
            <Heading level="h3" data-testid="header-title">
              {organisation.name}
            </Heading>
            {stateConfig ? (
              <Badge variant={stateConfig.variant} icon={stateConfig.icon} data-testid="state-badge">
                {t(stateConfig.labelTranslationKey)}
              </Badge>
            ) : (
              <Badge variant={'default'} data-testid="state-badge-default">
                {currentState}
              </Badge>
            )}
          </Flex>
        </Flex>
        {!isReviewOpen && showReviewButton && (
          <ButtonText icon="review" onPress={onOpenReview} data-testid="leave-review-button-header">
            {t('grants.reviews.button.leaveReview')}
          </ButtonText>
        )}
        {showUpdateEventsButton && (
          <SplitButton
            onPress={events.shortlist}
            onSelect={handleCLickSplitButtonMenu}
            data-testid="shortlist-button-header"
            menuItems={[
              {
                items: [
                  {
                    key: 'admin-reject-action',
                    label: t('button.reject'),
                    icon: 'reject'
                  }
                ]
              }
            ]}
          >
            {t('button.shortlist')}
          </SplitButton>
        )}
      </Flex>
      <Tabs
        items={tabs}
        activeTab={activeTab}
        onChangeTab={newTab => {
          setActiveTab(newTab as GrantProgramCandidateHeaderTab)
        }}
      />
      {selectedEvent === CandidateStateEvent.SHORTLIST && <ShortlistOrganisationModal {...adminActionModalProps} />}
      {selectedEvent === CandidateStateEvent.REJECT && <RejectOrganisationModal {...adminActionModalProps} />}
    </Flex>
  )
}

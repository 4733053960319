import {
  Button,
  ButtonText,
  CardDashboard,
  ConfirmationModal,
  Modal,
  Flex,
  Spacer,
  AcknowledgeModal,
  Loader,
  IllustratedMessage
} from '@percent/lemonade'
import { Trans, useTranslation } from 'react-i18next'
import { GrantProgramCandidateReviewProps } from './GrantProgramCandidateReview.types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { GrantProgramReviewQuestion } from './grantProgramReviewQuestion/GrantProgramReviewQuestion'
import styles from './GrantProgramCandidateReview.module.scss'
import { useState } from 'react'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/partner-dashboard/common/hooks/useQuery/useQuery'
import { useParams } from 'react-router-dom'
import { useMutation } from '@percent/partner-dashboard/common/hooks/useMutation/useMutation'
import { webLinks } from '@percent/partner-dashboard/constants/webLinks'

export function GrantProgramCandidateReview({
  isReviewOpen,
  onClose,
  organisationName,
  refreshReviews
}: Readonly<GrantProgramCandidateReviewProps>) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const { id: programId, candidateId } = useParams<{ id: string; candidateId: string }>()
  const { grantsService } = useServices()
  const { t } = useTranslation()

  const [{ data: reviewerForm, isLoading: isLoadingReviewerForm, errorMessage: reviewerErrorMessage }] = useQuery(
    grantsService.getGrantProgramReviewerForm,
    { programId }
  )

  const [{ isLoading: isSubmitLoading, errorMessage, success }, { apiFunc: submitReview }] = useMutation(
    grantsService.submitGrantProgramReviewerForm
  )

  const reviewerQuestions = reviewerForm?.pages.flatMap(page => page.questions) || []

  const { handleSubmit, values, dirty, isValid, setFieldValue } = useFormik({
    initialValues: reviewerQuestions.reduce((acc, question) => {
      acc[question.id] = ''
      return acc
    }, {} as Record<string, string | number>),
    validationSchema: Yup.object().shape(
      reviewerQuestions.reduce((acc, question) => {
        acc[question.id] = question.type === 'score' ? Yup.number().min(0).max(question.maxScore) : Yup.string()

        if (question.required) {
          acc[question.id] = acc[question.id].required()
        }
        return acc
      }, {} as Record<string, Yup.AnySchema>)
    ),
    onSubmit: async values => {
      const review = Object.entries(values).map(([questionId, answer]) => ({
        questionId,
        answer
      }))

      submitReview({ programId, candidateId, review })
    },
    enableReinitialize: true
  })

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true)
  }

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false)
  }

  const closeSuccessModal = () => {
    closeConfirmationModal()
    refreshReviews()
    onClose()
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('grants.nominationForm.review.confirmationModal.success.title')}
      description={t('grants.nominationForm.review.confirmationModal.success.description', {
        organisation: organisationName
      })}
      buttonText={t('button.close')}
      handleClose={closeSuccessModal}
      viewTestId="review-success-modal"
      buttonTestId="close-review-success-modal"
    />
  )

  const errorModal = errorMessage && (
    <AcknowledgeModal
      result="negative"
      title={t('grants.nominationForm.review.confirmationModal.error.title')}
      description={t('grants.nominationForm.review.confirmationModal.error.description')}
      buttonText={t('button.done')}
      handleClose={closeConfirmationModal}
      viewTestId="review-error-modal"
      buttonTestId="close-review-error-modal"
    />
  )

  if (!isReviewOpen) {
    return null
  }

  return (
    <div className={styles.reviewPanel}>
      <CardDashboard
        title={t('grants.reviewForm.leaveReview')}
        action={<ButtonText icon="close" onPress={onClose} data-testid="close-review-button" />}
        className={styles.card}
      >
        {reviewerErrorMessage && (
          <IllustratedMessage
            illustration="invalid-link"
            title={t('grants.nominationForm.review.error.title')}
            description={
              <Trans
                i18nKey="grants.nominationForm.review.error.description"
                components={{
                  a: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={webLinks.HELP_CENTER}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.linkHelpCenter}
                    />
                  )
                }}
                values={{ helpCenter: t('grants.nominationForm.review.error.helpCenter') }}
              />
            }
          />
        )}
        <Flex className={styles.questionsWrapper}>
          {isLoadingReviewerForm && <Loader />}
          {reviewerQuestions
            ?.sort((a, b) => a.order - b.order)
            ?.map(question => (
              <GrantProgramReviewQuestion
                key={question.id}
                question={question}
                setFieldValue={setFieldValue}
                value={values?.[question.id]}
              />
            ))}
          <Flex justify="flex-end" mt={48}>
            <Button
              onPress={openConfirmationModal}
              disabled={!dirty || !isValid}
              data-testid="leave-review-button-card"
            >
              {t('grants.reviews.button.leaveReview')}
            </Button>
          </Flex>
        </Flex>
      </CardDashboard>
      {confirmationModalOpen && (
        <Modal open={confirmationModalOpen} onClose={closeConfirmationModal}>
          {successModal || errorModal || (
            <ConfirmationModal
              title={t('grants.nominationForm.review.confirmationModal.title')}
              loading={isSubmitLoading}
              primaryButtonText={t('grants.reviews.button.leaveReview')}
              primaryBtnTestId="submit-review-button"
              secondaryButtonText={t('button.cancel')}
              handleSubmit={handleSubmit}
              handleClose={closeConfirmationModal}
            >
              <Trans
                i18nKey="grants.nominationForm.review.confirmationModal.description"
                components={{
                  b: <b />,
                  br: <Spacer axis="vertical" size={4} />
                }}
                values={{
                  organisation: organisationName
                }}
              />
            </ConfirmationModal>
          )}
        </Modal>
      )}
    </div>
  )
}

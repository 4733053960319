import {
  Badge,
  FlagAvatar,
  Flex,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TablePagination,
  TableProvider,
  TableRow,
  Tooltip,
  UserAvatar
} from '@percent/lemonade'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import { GrantProgramCandidatesTableProps } from './GrantProgramCandidatesTable.types'
import { Alpha3Code } from 'i18n-iso-countries'
import { RoutePath } from '../../../layout/Routes'
import { useHistory } from 'react-router-dom'
import currentStateMapping from '../../currentStateMapping/currentStateMapping'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { GrantProgramCandidateReviewer } from '@percent/partner-dashboard/services/grants/grantsService.types'

const allowedInitialsColors = [
  'gray[4]',
  'red[4]',
  'red[8]',
  'grape',
  'violet[4]',
  'violet[8]',
  'indigo',
  'blue',
  'cyan'
]

const sortReviewers = (reviewers: GrantProgramCandidateReviewer[], userId: string) => {
  return reviewers
    .filter(reviewer => reviewer.accountId === userId)
    .concat(reviewers.filter(reviewer => reviewer.accountId !== userId))
}

export function GrantProgramCandidatesTable({
  data,
  programId,
  programName,
  isLoading,
  previousPage,
  nextPage,
  totalResults,
  sortDirection,
  handleRequestSort,
  sortByColumn,
  filters
}: Readonly<GrantProgramCandidatesTableProps>) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { user } = useAuthState()
  const userId = user?.id

  const columnsHeaders: TableColumn[] = [
    { id: 'country', content: t('table.header.country'), isSortable: false },
    { id: 'organization', content: t('table.header.organization'), isSortable: false },
    { id: 'stage', content: t('table.header.stage'), isSortable: false },
    { id: 'reviewedBy', content: t('table.header.reviewedBy'), isSortable: false },
    { id: 'submittedAt', content: t('table.header.submittedAt'), isSortable: true }
  ]

  const columnsPrograms = columnsHeaders.map((column, _index) => ({
    id: column.id,
    content: column.content,
    isSortable: column.isSortable
  }))

  const rowCategoryClicked = (programId: string, candidateId: string) =>
    push({
      pathname: `${RoutePath.GRANT_PROGRAMS}/${programId}/applications/${candidateId}`,
      state: {
        organisationName: programName
      }
    })

  const formatDate = (date: string) => {
    return format(new Date(date), 'dd MMM yyyy HH:mm')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <TableProvider
      columns={columnsPrograms}
      totalResults={totalResults}
      isLoading={isLoading}
      handleRequestSort={handleRequestSort}
      sortDirection={sortDirection}
      sortByColumn={sortByColumn}
      illustration="no-grant-candidates"
      emptyTableTitle={t('grants.candidatesTable.emptyTable.title')}
      emptyTableDescription={t('grants.candidatesTable.emptyTable.description')}
    >
      {totalResults != null && totalResults > 0 && filters}
      <Table>
        <TableBody>
          {data?.map(({ id, organisation, currentState, reviewers, createdAt }) => {
            const stateConfig = currentStateMapping[currentState]

            const isUserReviewer = reviewers?.some(reviewer => reviewer.accountId === userId)

            const sortedReviewers = isUserReviewer && userId ? sortReviewers(reviewers, userId) : reviewers

            return (
              <TableRow
                key={`grant-program-candidate-${id}`}
                data-testid={`grant-program-candidate-${id}`}
                onClick={() => rowCategoryClicked(programId, id)}
              >
                <TableCell>
                  <FlagAvatar code3={organisation.countryCode as Alpha3Code} width={'28px'} />
                </TableCell>
                <TableCell>{organisation.name}</TableCell>
                <TableCell>
                  {stateConfig ? (
                    <Badge variant={stateConfig.variant} icon={stateConfig.icon}>
                      {t(stateConfig.labelTranslationKey)}
                    </Badge>
                  ) : (
                    <Badge variant={'default'}>{currentState}</Badge>
                  )}
                </TableCell>
                <TableCell>
                  <UserAvatar.Group>
                    {sortedReviewers
                      .slice(0, sortedReviewers.length > 4 ? 3 : 4)
                      .map(({ accountId: reviewerId, email }) => (
                        <Tooltip
                          key={reviewerId}
                          content={reviewerId === userId ? t('grants.candidatesTable.reviewedBy.tooltip') : email}
                          offsetValue={-2}
                        >
                          <div>
                            <UserAvatar
                              size="md"
                              color="initials"
                              name={email}
                              data-testid={`avatar-candidate-${id}-reviewer-${reviewerId}`}
                              allowedInitialsColors={allowedInitialsColors}
                              autoContrast={true}
                            />
                          </div>
                        </Tooltip>
                      ))}
                    {sortedReviewers.length > 4 && (
                      <Tooltip
                        content={sortedReviewers
                          .slice(3)
                          .map(reviewer => reviewer.email)
                          .join(', \n')}
                      >
                        <div>
                          <UserAvatar
                            size="md"
                            name={`+${sortedReviewers.length - 3}`}
                            data-testid="avatar-more-reviewers"
                            allowedInitialsColors={allowedInitialsColors}
                            autoContrast={true}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </UserAvatar.Group>
                </TableCell>
                <TableCell>{formatDate(createdAt)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Flex direction="column" p="10px">
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
    </TableProvider>
  )
}

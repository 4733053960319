import { Box, Paper, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQueryList, useQuery } from '@percent/partner-dashboard/common/hooks'
import { getFormattedDate } from '@percent/partner-dashboard/common/library/utility/date'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { VolunteeringSessionsTable } from './VolunteeringSessionsTable/VolunteeringSessionsTable'
import styles from './VolunteeringSessionsOpportunityContainer.module.scss'
import { Icon, Loader, Tooltip, Grid } from '@percent/lemonade'
import { useBreadcrumbs } from '../../../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { RoutePath } from '../../../layout/Routes'

export function VolunteeringSessionsOpportunityContainer() {
  const { t } = useTranslation()
  const { seriesOpportunityId } = useParams<{ seriesOpportunityId: string }>()
  const { workplaceGivingService } = useServices()

  const [{ data: parentData, errorMessage: parentDetailsErrorMessage, isLoading: isParentDataLoading }] = useQuery(
    workplaceGivingService.getVolunteeringOpportunityDetails,
    seriesOpportunityId
  )

  const [{ data, isLoading, errorMessage, totalResults }, { nextPage, previousPage, refresh }] = useQueryList(
    workplaceGivingService.getVolunteeringOpportunitiesList,
    {
      parentId: seriesOpportunityId,
      pageSize: 25
    }
  )

  useBreadcrumbs([
    {
      label: t('breadcrumb.workplaceGiving'),
      path: RoutePath.WORKPLACE_GIVING
    },
    {
      label: t('breadcrumb.volunteering'),
      path: RoutePath.WORKPLACE_GIVING_VOLUNTEERING
    },
    {
      label: seriesOpportunityId
    }
  ])

  if (isParentDataLoading) {
    return <Loader />
  }

  if (!parentData || parentDetailsErrorMessage || !!errorMessage) {
    return (
      <Box component={Paper}>
        <ErrorView errorMessage={parentDetailsErrorMessage || errorMessage} />
      </Box>
    )
  }

  return (
    <Grid gutter="xl">
      <Grid.Col span={12}>
        <Box component={Paper} className={styles.parentDetailsWrapper}>
          <Box className={styles.parentDetailsTitle}>
            <Typography variant="h6" color="secondary">
              {parentData.name}
            </Typography>
          </Box>
          <Box>
            <Typography>{t('table.header.nonprofit')}</Typography>
            <Typography>{parentData.organisation?.name ?? t('status.notAvailable')}</Typography>
          </Box>
          <Box>
            <Typography>{t('table.header.owner')}</Typography>
            <Typography>
              {parentData.organiser?.email ? (
                <Tooltip content={parentData.organiser?.email}>
                  <div className={styles.ownerWrapper}>
                    <span>{parentData.organiser.fullName}</span>
                    <span className={styles.email}>{parentData.organiser.email}</span>
                  </div>
                </Tooltip>
              ) : (
                t('status.notAvailable')
              )}
            </Typography>
          </Box>
          <Box>
            <Typography>{t('table.header.createdAt')}</Typography>
            <Typography>{getFormattedDate(parentData.createdAt)}</Typography>
          </Box>
          <Box>
            <Typography>{t('typography.volunteering.mainEvent')}</Typography>
            <Typography>
              <a
                href={`${process.env.NX_WPG_ADDRESS}/volunteer/opportunities/${parentData.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{parentData.name}</span> <Icon size={5} name="expand" color="black" />
              </a>
            </Typography>
          </Box>
        </Box>
      </Grid.Col>
      <Grid.Col span={12}>
        <VolunteeringSessionsTable
          data={data}
          isLoading={isLoading}
          totalResults={totalResults}
          errorMessage={errorMessage}
          nextPage={nextPage}
          previousPage={previousPage}
          refresh={refresh}
        />
      </Grid.Col>
    </Grid>
  )
}

import { GrantProgramsTable } from './grantProgramsTable/GrantProgramsTable'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { useBreadcrumbs } from '../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { useTranslation } from 'react-i18next'

export function GrantProgramsContainer() {
  const { grantsService } = useServices()
  const { t } = useTranslation()

  useBreadcrumbs([
    {
      label: t('breadcrumb.grants')
    }
  ])

  const [{ data: grantPrograms, isLoading: isLoadingPrograms }, { refresh: refreshGrantPrograms }] = useQuery(
    grantsService.getGrantPrograms,
    {}
  )

  return (
    <div data-testid="grant-programs-container">
      <GrantProgramsTable
        data={grantPrograms}
        isLoading={isLoadingPrograms}
        totalResults={grantPrograms?.length ?? 0}
        refresh={refreshGrantPrograms}
      />
    </div>
  )
}

import { Grid } from '@material-ui/core'

import { DonationListTable } from './DonationListTable/DonationListTable'
import { useBreadcrumbs } from '../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { useTranslation } from 'react-i18next'

export function DonationContainer() {
  const { t } = useTranslation()

  useBreadcrumbs([
    {
      label: t('breadcrumb.donations')
    }
  ])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DonationListTable />
      </Grid>
    </Grid>
  )
}

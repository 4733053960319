import { Table, TableBody, TableProvider, TableRow, TableCell, Loader } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import { RoutePath } from '../../layout/Routes'
import { GrantProgramsTableProps } from './GrantProgramsTable.types'

export function GrantProgramsTable({ data, isLoading, totalResults }: Readonly<GrantProgramsTableProps>) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const columnsPrograms = [
    { id: t('table.header.grantPrograms'), isSortable: false },
    { id: t('table.header.startDate'), isSortable: false },
    { id: t('table.header.endDate'), isSortable: false }
  ]

  const rowCategoryClicked = (programId: string) => push(`${RoutePath.GRANT_PROGRAMS}/${programId}/applications`)

  const formatDate = (date: string) => {
    return format(new Date(date), 'dd MMM yyyy')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <TableProvider
      columns={columnsPrograms}
      totalResults={totalResults}
      isLoading={isLoading}
      illustration="no-grant-programs"
      emptyTableTitle={t('grants.programsTable.emptyTable.title')}
      emptyTableDescription={t('grants.programsTable.emptyTable.description')}
    >
      <Table>
        <TableBody>
          {data?.map(({ id, name, opensAt, closesAt }, _index) => (
            <TableRow
              key={`grant-programs-list-${id}`}
              onClick={() => rowCategoryClicked(id)}
              data-testid={`${name}-program`}
            >
              <TableCell>{name}</TableCell>
              <TableCell>{formatDate(opensAt)}</TableCell>
              <TableCell>{closesAt ? formatDate(closesAt) : t('status.notAvailable')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableProvider>
  )
}

import { ButtonTextV2, Flex, FormField, TextArea } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { GrantProgramReviewQuestionProps } from './GrantProgramReviewQuestion.types'
import styles from './GrantProgramReviewQuestion.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export function GrantProgramReviewQuestion({ question, value, setFieldValue }: GrantProgramReviewQuestionProps) {
  const { t } = useTranslation()

  const { id, type, placeholder, required, label } = question

  return (
    <Flex direction="column" gap={16}>
      <FormField label={label} necessity={required ? 'required' : 'optional'}>
        <Flex flex={1} w="100%">
          {type === 'score' && (
            <Flex direction="column" gap={8} flex={1}>
              <Flex gap={8} justify="space-between" data-testid={`${id}-score-buttons`}>
                {Array.from({ length: question.maxScore }, (_, i) => (
                  <ButtonTextV2
                    key={`${id}-${i + 1}`}
                    className={cx({ scoreButton: true, active: value === i + 1 })}
                    onClick={() => setFieldValue(id, i + 1)}
                    data-testid={`q-${id}-btn-score-${i + 1}`}
                  >
                    {i + 1}
                  </ButtonTextV2>
                ))}
              </Flex>
              <Flex justify="space-between">
                <span className={styles.scoreScaleDescription}>
                  {t('grants.nominationForm.question.number.stronglyDisagree')}
                </span>
                <span className={styles.scoreScaleDescription}>
                  {t('grants.nominationForm.question.number.stronglyAgree')}
                </span>
              </Flex>
            </Flex>
          )}
          {type === 'textarea' && (
            <TextArea
              name={id}
              placeholder={placeholder}
              onChange={e => setFieldValue(id, e.target.value)}
              data-testid={`q-${id}-text-area`}
            />
          )}
        </Flex>
      </FormField>
    </Flex>
  )
}

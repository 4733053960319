import { Avatar as MantineAvatar } from '@mantine/core'
import { UserAvatarProps } from './user-avatar.types'
import styled from 'styled-components'

const StyledMantineAvatar = styled(MantineAvatar)`
  ${({ theme }) =>
    `
    > span {
      font-size: ${theme.fontSizes.bodySmall};
    }
  `}
`

export const UserAvatar = ({ ...props }: UserAvatarProps) => (
  <StyledMantineAvatar {...props} />
)

UserAvatar.Group = MantineAvatar.Group

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { BreadcrumbsContextController } from './breadcrumbsContext/breadcrumbsContextController/BreadcrumbsContextController'
import { Routes } from './Routes'
import { TopBar } from './topbar/TopBar'
import { NoPrivilegeAccessView } from '@percent/partner-dashboard/common/components'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { Flex, Logo, Navigation } from '@percent/lemonade'
import { useAuthState, useFeatureFlag } from '@percent/partner-dashboard/common/hooks'
import { selectScopes } from '@percent/partner-dashboard/context/auth'
import { PartnerScopes } from '@percent/partner-dashboard/constants/scopes'
import { NavItemProps } from 'libs/shared/ui-lemonade/src/components/navigation/navigation.types'
import { NavConfigItem, GetPDNavItemsParams, CreateNavItemParams } from './Layout.types'
import styles from './Layout.module.scss'
import { drawerWidth } from '@percent/partner-dashboard/app/theme/Theme'

function createNavItem({ condition, icon, label, href, children, pathname }: CreateNavItemParams) {
  if (!condition) return null

  return {
    icon,
    label,
    href,
    isActive: pathname.includes(href.includes('?') ? href.split(/\?/)[0] : href.split(/\/+/).filter(Boolean)[0]),
    ...(children
      ? {
          children: children.map(child => ({ ...child, isActive: child.pathname.includes(child.href.split('?')[0]) }))
        }
      : {})
  }
}

function getPDNavItems({ pathname, scopes, userCan, t, slackInstallFeatureFlag }: GetPDNavItemsParams) {
  const navConfig: NavConfigItem[] = [
    {
      icon: 'menu-dashboard',
      label: t('menu.dashboard'),
      href: '/dashboard',
      condition: true
    },
    {
      icon: 'menu-donations',
      label: t('menu.donations'),
      href: '/donations',
      condition: (scopes[PartnerScopes.Donation] || scopes[PartnerScopes.DonationSession]) && userCan('viewDonations')
    },
    {
      icon: 'menu-categories',
      label: t('menu.categories'),
      href: '/categories',
      condition: scopes[PartnerScopes.Organisation] && userCan('viewCategories')
    },
    {
      icon: 'menu-nonprofits',
      label: t('menu.nonprofits'),
      href: '/nonprofits',
      condition: scopes[PartnerScopes.Donation] && scopes[PartnerScopes.Organisation] && userCan('viewNonprofits')
    },
    {
      icon: 'menu-workplace-giving',
      label: t('menu.workplaceGiving'),
      href: '/workplace-giving/employees',
      condition: scopes[PartnerScopes.WorkplaceGiving] && (userCan('manageOwners') || userCan('manageAdmins')),
      children: [
        {
          label: t('menu.workplaceGivingSubmenu.employees'),
          href: '/workplace-giving/employees'
        },
        ...(scopes[PartnerScopes.DonationLink] && userCan('viewFundraisers')
          ? [
              {
                label: t('menu.workplaceGivingSubmenu.fundraisers'),
                href: '/workplace-giving/fundraisers'
              }
            ]
          : []),
        ...(scopes[PartnerScopes.Volunteering] &&
        userCan('viewOpportunities') &&
        (userCan('manageOwners') || userCan('manageAdmins'))
          ? [
              {
                label: t('menu.workplaceGivingSubmenu.volunteering'),
                href: '/workplace-giving/volunteering'
              }
            ]
          : []),
        ...(slackInstallFeatureFlag
          ? [{ label: t('menu.workplaceGivingSubmenu.integrations'), href: '/workplace-giving/integrations' }]
          : [])
      ]
    },
    {
      icon: 'menu-grants',
      label: t('menu.grants'),
      href: '/grant-programs',
      condition: userCan('viewGrants')
    },
    {
      icon: 'menu-verifications',
      label: t('menu.verifications'),
      href: '/verifications',
      condition:
        (scopes[PartnerScopes.ValidationInvite] || scopes[PartnerScopes.SingleValidationSubmissionApi]) &&
        userCan('viewVerifications')
    },
    {
      icon: 'menu-keys',
      label: t('menu.keys'),
      href: '/keys',
      condition: userCan('viewApiKeys')
    }
  ]

  return navConfig
    .map(item =>
      createNavItem({
        ...item,
        pathname,
        children: item.children ? item.children.map(child => ({ ...child, pathname })) : undefined
      })
    )
    .filter((item): item is NavItemProps => !!item)
}

export function Layout() {
  const { t } = useTranslation()
  const { userCan } = usePermissionCheck()
  const { pathname } = useLocation()
  const { partner } = useAuthState()
  const scopes = selectScopes(partner?.scopes)
  const { slackInstallFeatureFlag } = useFeatureFlag()

  if (userCan('viewDashboard')) {
    return (
      <BreadcrumbsContextController>
        <TopBar />
        <Navigation
          logo={<Logo className={styles.logo} />}
          items={getPDNavItems({
            pathname,
            scopes,
            userCan,
            t,
            slackInstallFeatureFlag
          })}
        />
        <Flex direction="column" ml={drawerWidth} p={40}>
          <Routes />
        </Flex>
      </BreadcrumbsContextController>
    )
  }

  return <NoPrivilegeAccessView />
}

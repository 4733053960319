import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { AppBar, Grid, MenuItem, Toolbar, Menu, Typography } from '@material-ui/core'

import { Flex, Icon, Breadcrumbs } from '@percent/lemonade'
import { ReactComponent as NavigateNextIcon } from '@percent/partner-dashboard/common/assets/images/navigate-next-icon.svg'
import { useAuthState, useAuthDispatch } from '@percent/partner-dashboard/common/hooks'
import styles from './TopBar.module.scss'
import { ReactComponent as DropdownSVG } from '@percent/partner-dashboard/common/assets/images/dropdown.svg'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { SET_LOGOUT } from '@percent/partner-dashboard/context/auth'
import { useTranslation } from 'react-i18next'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { useBreadcrumbs } from '../breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'

export function TopBar() {
  const { t } = useTranslation()
  const dispatch = useAuthDispatch()
  const { authService } = useServices()
  const { breadcrumb } = useBreadcrumbs()
  const { user } = useAuthState()
  const { userCan } = usePermissionCheck()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    authService.logout().then(() => {
      localStorage.removeItem('authState')
      dispatch({
        type: SET_LOGOUT
      })
    })

    return <Redirect to="/signin" />
  }

  return (
    <AppBar position="static">
      <Toolbar className={styles.toolBar}>
        <Flex w="100%">
          <Breadcrumbs items={breadcrumb} separator={<NavigateNextIcon />} />
        </Flex>
        <Grid direction="row-reverse">
          <MenuItem onClick={handleClick} className={styles.menuItem}>
            <div className={styles.userDetail}>
              <Typography className={styles.nameText} variant="body1">
                {user?.fullName ?? user?.preferredName ?? user?.email}
              </Typography>
              <Typography className={styles.roleText} variant="body2" data-testid="nav-user-role">
                {user?.role?.displayName}
              </Typography>
            </div>
            <DropdownSVG className={anchorEl ? styles.dropdown : undefined} />
          </MenuItem>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={styles.userProfileMenu}
            >
              <Link to="/my-account">
                <MenuItem className={styles.userProfileMenuItem}>
                  <Icon name="user-circle" size={6} color="gray500" />
                  {t('menu.accountSettings')}
                </MenuItem>
              </Link>
              {userCan('viewUsers') && (
                <Link to="/users">
                  <MenuItem className={styles.userProfileMenuItem}>
                    <Icon name="users" size={6} color="gray500" />
                    {t('menu.myTeam')}
                  </MenuItem>
                </Link>
              )}
              <MenuItem className={styles.userProfileMenuItem} onClick={handleLogout}>
                <Icon name="log-out" size={6} color="gray500" />
                {t('menu.logout')}
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

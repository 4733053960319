import { FileWithPath, MIME_TYPES } from '@mantine/dropzone'

import {
  AcknowledgeModal,
  Alert,
  Dropzone,
  FullPageFormLayout,
  Heading,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableProvider,
  TableRow
} from '@percent/lemonade'
import { useState } from 'react'
import { WizardHeader } from '../../../../../common/components/wizardHeader/WizardHeader'
import styles from './BulkWalletWizard.module.scss'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/partner-dashboard/common/hooks'

export enum BulkWalletErrorCode {
  InvalidRecords = 'donation_wallet/add_wallet_balances/invalid_records',
  MissingHeaders = 'donation_wallet/add_wallet_balances/missing_required_csv_headers',
  InvalidCsv = 'donation_wallet/add_wallet_balances/invalid_csv_file',
  EmptyCsv = 'donation_wallet/add_wallet_balances/empty_csv_file',
  CsvRowsExceeded = 'donation_wallet/add_wallet_balances/max_csv_rows_exceeded'
}
export function BulkWalletWizard() {
  const [file, setFile] = useState<FileWithPath[]>([])
  const history = useHistory()
  const { t } = useTranslation()
  const [modalState, setModalState] = useState({ isSuccess: false, isError: false })
  const { walletBalanceService } = useServices()
  const [fileErrorCode, setFileErrorCode] = useState<string | null>(null)

  const handleCloseSuccessModal = () => {
    setModalState(prev => ({ ...prev, isSuccess: false }))
    history.push('/workplace-giving/employees')
  }

  const handleCloseErrorModal = () => {
    setModalState(prev => ({ ...prev, isError: false }))
  }

  const handleFileChange = (newFile: FileWithPath[]) => {
    setFile(newFile)
    setFileErrorCode(null)
  }

  const columns = [
    { id: 'Row', props: { width: '10%' } },
    { id: 'Field', props: { width: '20%' } },
    { id: 'Field value', props: { width: '30%' } },
    { id: 'Error message', props: { width: '40%' } }
  ]

  const [{ data, isLoading, error }, { apiFunc: addBatchWalletBalanceFunc }] = useMutation(
    walletBalanceService.addBatchWalletBalance,
    () => {
      setModalState(prev => ({ ...prev, isSuccess: true }))
    },
    apiError => {
      const errorCode = apiError?.response?.data?.error?.code

      if (Object.values(BulkWalletErrorCode).includes(errorCode as BulkWalletErrorCode)) {
        setFileErrorCode(errorCode as BulkWalletErrorCode)
      } else {
        setModalState(prev => ({ ...prev, isError: true }))
      }
    }
  )

  const handleBatchWalletBalance = () => {
    addBatchWalletBalanceFunc(file[0])
  }

  const isFileErrorInvalidRecords = fileErrorCode === BulkWalletErrorCode.InvalidRecords
  const isFileError = fileErrorCode && fileErrorCode !== BulkWalletErrorCode.InvalidRecords

  const totalErrors = error?.rowErrors?.length
  const uniqueRows = new Set(error?.rowErrors?.map(rowError => rowError.row)).size

  return (
    <div className={styles.bulkWalletWrapper}>
      <FullPageFormLayout
        actionsHeader={
          <WizardHeader
            title={t('typography.bulkWalletTopup')}
            onCancel={() => history.push('/workplace-giving/employees')}
            onPublish={handleBatchWalletBalance}
            isPublishDisabled={!!fileErrorCode || !file.length}
            isLoading={isLoading}
            publishBtnCopy={t('button.addFunds')}
            toolTipCopy={fileErrorCode ? t('toolTip.reUploadCsv') : t('toolTip.addFunds')}
          />
        }
      >
        <div className={styles.bulkWalletContent}>
          <div>
            <Heading level="h2">{t('typography.uploadFile')}</Heading>
            <p className={styles.uploadDescription}>
              <Trans
                i18nKey="typography.uploadDescription"
                components={{
                  a: (
                    <a
                      href="/assets/bulk_wallet_template.csv"
                      download="bulk_wallet_template.csv"
                      className={styles.uploadlink}
                    >
                      {t('typography.uploadDescription')}
                    </a>
                  )
                }}
              />
            </p>
            <ul className={styles.uploadList}>
              <li>
                <Trans i18nKey="typography.employeesEmailAdress" components={{ b: <strong /> }} />
              </li>
              <li>
                <Trans i18nKey="typography.addWalletBalance" components={{ b: <strong /> }} />
              </li>
              <li>
                <Trans i18nKey="typography.localCurrency" components={{ b: <strong /> }} />
              </li>
            </ul>
          </div>
          <Dropzone
            files={file}
            onChange={handleFileChange}
            maxFiles={1}
            maxSize={1024 * 1024}
            accept={[MIME_TYPES.csv]}
            title={t('typography.selectCsvFile')}
            description={t('typography.uploadCsvFile')}
            testId="bulk-wallet-upload"
          />
          {isFileErrorInvalidRecords || isFileError ? (
            <Alert
              variant="error"
              title={
                isFileError
                  ? t('errorMessage.bulkWalletTopup.invalidCsvFile')
                  : t('errorMessage.bulkWalletTopup.invalidRows', { count: totalErrors, totalErrors, uniqueRows })
              }
            >
              {isFileError
                ? error?.reasons?.[0] || error?.message
                : t('errorMessage.bulkWalletTopup.reviewErrors', { count: totalErrors })}
            </Alert>
          ) : null}

          {isFileErrorInvalidRecords && (
            <div className={styles.bulkWalletTable} data-testid="bulk-wallet-table">
              <TableProvider
                columns={columns}
                isLoading={false}
                illustration={'nothing-to-see'}
                totalResults={null}
                emptyTableTitle=""
                emptyTableDescription=""
              >
                <Table>
                  <TableBody>
                    {error?.rowErrors?.map((rowError, index) => (
                      <TableRow key={`${rowError.fieldValue}-${index}`}>
                        <TableCell>{rowError.row}</TableCell>
                        <TableCell>{rowError.field}</TableCell>
                        <TableCell>{rowError.fieldValue}</TableCell>
                        <TableCell>{rowError.message}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableProvider>
            </div>
          )}
        </div>
      </FullPageFormLayout>

      {data && (
        <Modal open={modalState.isSuccess} onClose={handleCloseSuccessModal} aria-labelledby="add-bulk-wallet-success">
          <AcknowledgeModal
            result="positive"
            title={t('dialog.employees.bulkWallet.success.title')}
            description={t('dialog.employees.bulkWallet.success.description')}
            buttonText={t('button.close')}
            handleClose={handleCloseSuccessModal}
            buttonTestId="success-modal-btn"
          />
        </Modal>
      )}

      <Modal open={modalState.isError} onClose={handleCloseErrorModal} aria-labelledby="add-bulk-wallet-error">
        <AcknowledgeModal
          result="negative"
          title={t('dialog.employees.bulkWallet.error.title')}
          description={t('dialog.employees.bulkWallet.error.description')}
          buttonText={t('button.close')}
          handleClose={handleCloseErrorModal}
          buttonTestId="error-modal-btn"
        />
      </Modal>
    </div>
  )
}

import cx from 'classnames'
import styles from './OuterCard.module.scss'
import { OuterCardProps } from './OuterCard.types'
import { Badge, Flex } from '@percent/lemonade'

export function OuterCard({
  badgeContent,
  badgeVariant = 'default',
  customBgColour = 'default',
  children,
  customHeader,
  dataTestId = 'outer-card'
}: Readonly<OuterCardProps>) {
  const bgClass = customBgColour === 'info' ? styles.infoBg : styles.defaultBg

  return (
    <Flex className={cx(styles.outerCard, bgClass)} data-testid={dataTestId}>
      {badgeContent && (
        <Flex pb="8px">
          <div className={styles.badge}>
            <Badge variant={badgeVariant} data-testid="badge">
              {badgeContent}
            </Badge>
          </div>
        </Flex>
      )}
      {customHeader}
      {children}
    </Flex>
  )
}

import { Breadcrumbs as MantineBreadcrumbs, Anchor } from '@mantine/core'
import { BreadcrumbsProps } from './breadcrumbs.types'
import { Link } from 'react-router-dom'
import { theme } from '../../theme'
import styles from './breadcrumbs.module.scss'

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  return (
    <MantineBreadcrumbs
      {...props}
      className={styles.breadcrumbs}
      data-testid="breadcrumbs"
    >
      {props.items.map((item, i) => {
        const isLastElement = i === props.items.length - 1
        return (
          <Anchor
            {...props.anchorProps}
            component={Link}
            key={item.label}
            to={item.path ?? '#'}
            c={
              isLastElement ? theme.colors.text_primary : theme.colors.goodstack
            }
            fw={isLastElement ? '600' : '400'}
            underline={isLastElement ? 'never' : 'always'}
          >
            {item.label}
          </Anchor>
        )
      })}
    </MantineBreadcrumbs>
  )
}

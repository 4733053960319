import { useTranslation } from 'react-i18next'
import { ChangeEvent, EventHandler, KeyboardEvent, useState } from 'react'
import {
  Button,
  SearchInput,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow,
  TableSearchAndFilters,
  Tooltip
} from '@percent/lemonade'
import { formatMoney } from '@percent/utility'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { useAuthState, useFeatureFlag } from '@percent/partner-dashboard/common/hooks'
import { EmployeesTableProps } from './EmployeesTable.types'
import { EmployeesActionsMenu } from '../EmployeesActionMenu/EmployeesActionMenu'
import { useEmployeesActionModal } from '../useEmployeesActionModal/useEmployeesActionModal'
import { UpdatedEmployee } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import { AddWalletBalanceModal } from '../EmployeesModal/AddWalletBalanceModal'
import { UpdateMatchingBudgetModal } from '../EmployeesModal/UpdateMatchingBudgetModal'
import { AssignAchievementModal } from '../EmployeesModal/AssignAchievementModal'
import { useHistory } from 'react-router-dom'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'

export function EmployeesTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  queryParams,
  setQueryParams,
  refresh
}: Readonly<EmployeesTableProps>) {
  const [searchValue, setSearchValue] = useState<string>(queryParams?.filter || '')
  const { partner } = useAuthState()
  const { t } = useTranslation()
  const {
    isAddWalletBalanceModalOpen,
    selectedEmployee,
    openAddWalletBalanceModal,
    closeAddWalletBalanceModal,
    isUpdateMatchingBudgetModalOpen,
    openUpdateMatchingBudgetModal,
    closeUpdateMatchingBudgetModal,
    isAssignAchievementModalOpen,
    openAssignAchievementBudgetModal,
    closeAssignAchievementBudgetModal
  } = useEmployeesActionModal()
  const history = useHistory()
  const { userCan } = usePermissionCheck()

  const showBulkWalletWizard = () => {
    history.push('/workplace-giving/employees/bulk-wallet-wizard')
  }

  const hasDonationWallet = partner?.scopes?.some(data => data.scopeName.includes('donation_wallet'))
  const hasDonationMatching = partner?.scopes?.some(data => data.scopeName.includes('donation_matching'))
  const hasGamification = partner?.scopes?.some(data => data.scopeName.includes('gamification'))

  const columns = [
    { id: t('table.header.fullName'), props: { width: '20%' } },
    { id: t('table.header.email'), props: { width: '30%' } },
    { id: t('table.header.team'), props: { width: '15%' } },
    ...(hasDonationWallet ? [{ id: t('table.header.currentWalletBalance'), props: { width: '15%' } }] : []),
    ...(hasDonationMatching
      ? [
          {
            id: t('table.header.matchingBudget'),
            content: (
              <Tooltip content={t('toolTip.workplaceGiving.matchingBudget')}>
                <span>{t('table.header.matchingBudget')}</span>
              </Tooltip>
            ),
            props: { width: '15%' }
          }
        ]
      : []),
    { id: t('table.header.actions'), props: { width: '5%' } }
  ]

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (searchValue.length <= 255) {
      if (event.key === 'Enter') {
        setQueryParams?.({
          filter: (event.target as HTMLInputElement).value.trim()
        })
      }
    }

    if (event.key === 'Escape') {
      setQueryParams?.({
        filter: ''
      })
      return setSearchValue('')
    }

    return
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <TableProvider
        columns={columns}
        totalResults={totalResults}
        isLoading={isLoading || !data}
        illustration="no-results"
        emptyTableTitle={
          queryParams?.filter
            ? t('typography.noSearchTableListTitle', {
                searchTerm: queryParams?.filter
              })
            : t('typography.noListTitle', { listType: 'employees' })
        }
        emptyTableDescription={
          queryParams?.filter
            ? t('typography.noSearchTableListDescription')
            : t('typography.noListDescription', { listType: 'employees' })
        }
      >
        <TableSearchAndFilters>
          <SearchInput
            name="search"
            value={searchValue}
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            handleClearValue={() => {
              setSearchValue('')
              setQueryParams?.({
                filter: ''
              })
            }}
            placeholder={t('table.employeesListSearchPlaceholder')}
            status={searchValue.length >= 255 ? 'danger' : 'default'}
            statusMessage={t('errorMessage.shouldNotExceed255')}
          />
          {userCan('manageDonationWallet') && (
            <Button data-testid="bulk-wallet-top-up" onPress={showBulkWalletWizard}>
              {t('button.bulkWalletTopUp')}
            </Button>
          )}
        </TableSearchAndFilters>
        <Table>
          <TableBody>
            {data?.map(employee => (
              <TableRow key={employee.id}>
                <TableCell>{employee.fullName || t('status.notAvailable')}</TableCell>
                <TableCell>
                  <span>{employee.email}</span>
                </TableCell>
                <TableCell>
                  {employee.team.length > 1 ? (
                    <Tooltip content={employee.team.join(', ')}>
                      {t('typography.teams', {
                        teamCount: employee.team.length
                      })}
                    </Tooltip>
                  ) : employee.team.length === 1 ? (
                    employee.team
                  ) : (
                    t('status.notAvailable')
                  )}
                </TableCell>
                {hasDonationWallet && (
                  <TableCell>
                    {employee?.wallet ? (
                      <span>{formatMoney(employee.wallet.balance, { hideDecimalsIfZero: true })}</span>
                    ) : (
                      t('status.notAvailable')
                    )}
                  </TableCell>
                )}
                {hasDonationMatching && (
                  <TableCell>
                    {employee?.matching ? (
                      <span>
                        {`${formatMoney(employee.matching.used, { hideDecimalsIfZero: true })}/${formatMoney(
                          employee.matching.total,
                          { hideDecimalsIfZero: true }
                        )}`}
                      </span>
                    ) : (
                      t('status.notAvailable')
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <EmployeesActionsMenu
                    items={[
                      ...(userCan('manageDonationWallet') && hasDonationWallet
                        ? [{ key: 'add', label: t('dialog.employees.wallet.title') }]
                        : []),
                      { key: 'update', label: t('dialog.employees.matching.title') },
                      ...(hasGamification
                        ? [{ key: 'assignAchievement', label: t('dialog.employees.achievements.title') }]
                        : [])
                    ]}
                    handleSelect={(actionKey: string) => {
                      switch (actionKey) {
                        case 'add':
                          openAddWalletBalanceModal(employee as UpdatedEmployee)
                          break
                        case 'update':
                          openUpdateMatchingBudgetModal(employee as UpdatedEmployee)
                          break
                        case 'assignAchievement':
                          openAssignAchievementBudgetModal(employee as UpdatedEmployee)
                          break
                      }
                    }}
                    currency={employee.currency}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </TableProvider>
      {selectedEmployee && (
        <AddWalletBalanceModal
          isOpen={isAddWalletBalanceModalOpen}
          employee={selectedEmployee}
          onClose={closeAddWalletBalanceModal}
          refresh={refresh}
        />
      )}
      {selectedEmployee && (
        <UpdateMatchingBudgetModal
          isOpen={isUpdateMatchingBudgetModalOpen}
          employee={selectedEmployee}
          onClose={closeUpdateMatchingBudgetModal}
          refresh={refresh}
        />
      )}
      {selectedEmployee && (
        <AssignAchievementModal
          isOpen={isAssignAchievementModalOpen}
          employee={selectedEmployee}
          onClose={closeAssignAchievementBudgetModal}
          refresh={refresh}
        />
      )}
    </>
  )
}

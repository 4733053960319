import { Alpha3Code } from 'i18n-iso-countries'
import { PBPListResponse, PBPResponse } from '../types/pbpResponses.types'
import { QueryParam } from '@percent/partner-dashboard/common/queryParams/queryParams.types'

export enum CurrentState {
  NOMINATED = 'nominated',
  SHORTLISTED = 'shortlisted',
  REJECTED = 'rejected'
}

// This is the same as CurrentState but with the state values as stored in the grant_program_candidate table
export enum CurrentStateDB {
  NOMINATED = 'STATE_NOMINATED',
  SHORTLISTED = 'STATE_NOMINATION_SHORTLISTED',
  REJECTED = 'STATE_NOMINATION_REJECTED'
}

export enum CandidateStateEvent {
  SHORTLIST = 'EVENT_NOMINATION_ADMIN_SHORTLISTED',
  REJECT = 'EVENT_NOMINATION_ADMIN_REJECTED'
}

export type GrantProgram = {
  id: string
  name: string
  opensAt: string
  closesAt?: string
  nominationsEnabled: boolean
  nominationsCloseAt: string
  partnerId: string
  validationSubmissionConfigurationId: string | null
}

export type GrantProgramsResponse = PBPResponse<GrantProgram[]>

export type GrantProgramCandidate = {
  id: string
  createdAt: string
  organisation: {
    name: string
    countryCode: string
  }
  reviewers: GrantProgramCandidateReviewer[]
  currentState: CurrentState
}

export type GrantProgramCandidatesResponse = PBPListResponse<GrantProgramCandidate>

export type GrantProgramCandidateResponse = {
  id: string
  organisation: {
    name: string
    website?: string
    email?: string
    country: Alpha3Code
    address?: string
    registryId?: string
    logo?: string
  }
  currentState: CurrentState
}

export type GrantProgramCandidateNominationDetails = {
  questionId: string
  questionLabel: string
  originalLocale: string
  translations: Record<string, string> & {
    'en-US': string
  }
}

export type GrantProgramCandidateNomination = {
  id: string
  nominator: {
    fullName: string
    email: string
    hostClub?: string
  }
  details: GrantProgramCandidateNominationDetails[]
  submittedAt: string
}

export type GrantProgramCandidateNominationsResponse = {
  candidateId: string
  nominations: GrantProgramCandidateNomination[]
}

export type GrantProgramCandidateReviewsResponse = GrantProgramCandidateReview[]

type BaseGrantProgramReviewerFormQuestion = {
  id: string
  key: string
  label: string
  placeholder?: string
  required: boolean
  order: number
}

export type GrantProgramReviewerFormQuestion =
  | (BaseGrantProgramReviewerFormQuestion & { type: 'textarea' })
  | (BaseGrantProgramReviewerFormQuestion & { type: 'score'; maxScore: number })

export type GrantProgramReviewerFormPage = {
  id: string
  title: string
  description: string
  order: number
  questions: GrantProgramReviewerFormQuestion[]
}

export type GrantProgramReviewerFormResponse = {
  pages: GrantProgramReviewerFormPage[]
}

export type GrantProgramCandidateReviewer = {
  accountId: string
  fullName?: string
  email: string
}

export type GrantProgramCandidateReviewQuestion = {
  questionId: string
  questionLabel: string
  questionType: 'comment' | 'score'
  answer: string
}

export type GrantProgramCandidateReview = {
  reviewer: GrantProgramCandidateReviewer
  review: GrantProgramCandidateReviewQuestion[]
  createdAt: string
}

type GrantProgramReview = {
  questionId: string
  answer: string | number
}

export type SubmitGrantProgramReviewerFormParams = {
  programId: string
  candidateId: string
  review: GrantProgramReview[]
}

export type GetProgramParams = {
  programId: string
}

export type GetCandidatesParams = {
  programId: string
  pageSize?: number
  direction?: QueryParam<'ASC' | 'DESC'>
  searchTerm?: QueryParam<string>
  currentState?: QueryParam<CurrentStateDB[]>
}

export type GetGrantCandidateParams = {
  candidateId: string
  programId: string
}

export type GetGrantProgramReviewerFormParams = {
  programId: string
}
export type ChangeGrantProgramCandidateStateParams = GetGrantCandidateParams & {
  event: CandidateStateEvent
}

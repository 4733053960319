import { Accordion, CardContent, CardDashboard } from '@percent/lemonade'
import CustomAccordion from '../../components/customAccordion/CustomAccordion'
import { OuterCard } from '../../components/outerCard/OuterCard'
import { LocaleSwitchHeader } from '../localeSwitchHeader/LocaleSwitchHeader'
import { GrantProgramCandidateNominationProps } from './GrantProgramCandidateNomination.types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import classNames from 'classnames/bind'
import styles from './GrantProgramCandidateNomination.module.scss'
import { DEFAULT_NOMINATION_LOCALES } from '../localeSwitchHeader/LocaleSwitchHeader.types'
import { NominationLocale } from '../localeSwitchHeader/LocaleSwitchHeader.types'

const cx = classNames.bind(styles)

export const GrantProgramCandidateNomination = ({
  nomination,
  index
}: Readonly<GrantProgramCandidateNominationProps>) => {
  const [selectedLocale, setSelectedLocale] = useState<string>(NominationLocale.EN_US)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const { t } = useTranslation()

  const originalLocale = nomination.details[0]?.originalLocale ?? NominationLocale.EN_US
  const translationAvailable = nomination.details.some(detail => detail.translations[originalLocale])
  const isLanguageOtherThanDefault = !DEFAULT_NOMINATION_LOCALES.includes(originalLocale)

  const handleLocaleChange = (newLocale: string) => {
    setShouldAnimate(true)
    setSelectedLocale(newLocale)
    setTimeout(() => setShouldAnimate(false), 500)
  }

  const valueOrNotAvailableString = (value?: string | null) => value || t('typography.notAvailable')

  const animateTranslation = translationAvailable && isLanguageOtherThanDefault && shouldAnimate

  return (
    <CardDashboard
      key={nomination.id}
      title={t('grants.nominations.nomination', { number: index })}
      data-testid={`nomination-${index}`}
    >
      <CardContent title={t('grants.nominations.nomination.fullName')} testId={`nominator-full-name-${index}`}>
        {nomination.nominator.fullName}
      </CardContent>
      <CardContent title={t('grants.nominations.nomination.email')} testId={`nominator-email-${index}`}>
        <a href={`mailto:${nomination.nominator.email}`}>{nomination.nominator.email}</a>
      </CardContent>
      <CardContent title={t('grants.nominations.nomination.hostClub')} testId={`nominator-host-club-${index}`}>
        {valueOrNotAvailableString(nomination.nominator.hostClub)}
      </CardContent>
      <OuterCard
        dataTestId={`nomination-${index}-details`}
        customBgColour={isLanguageOtherThanDefault && translationAvailable ? 'info' : 'default'}
        customHeader={
          <LocaleSwitchHeader
            selectedLocale={selectedLocale}
            setSelectedLocale={handleLocaleChange}
            originalLocale={originalLocale}
            translationAvailable={translationAvailable}
          />
        }
      >
        <div key={selectedLocale} className={cx(styles.innerCard, styles.defaultBorder)} data-testid="inner-card">
          {nomination.details.map(({ questionId, questionLabel, translations }, i) => (
            <div
              key={questionId}
              className={cx(
                styles.innerCardSectionAccordion,
                styles.defaultTopBorder,
                animateTranslation && styles.fadeInFromTop
              )}
              style={{ animationDelay: `${i * 0.1}s` }}
            >
              <CustomAccordion>
                <Accordion.Item value={`additional-question-${questionId}`} data-testid={`question-${questionId}`}>
                  <Accordion.Control data-testid={`question-${questionId}-label`}>{questionLabel}</Accordion.Control>
                  <Accordion.Panel data-testid={`question-${questionId}-answer`}>
                    {translations[selectedLocale]}
                  </Accordion.Panel>
                </Accordion.Item>
              </CustomAccordion>
            </div>
          ))}
        </div>
      </OuterCard>
    </CardDashboard>
  )
}

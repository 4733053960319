import { useTranslation } from 'react-i18next'

import { FilterList, Flex, SearchInput, TableSearchAndFilters } from '@percent/lemonade'
import { useOrganisationSearch } from './hooks/useOrganisationSearch'
import { SearchAndFiltersProps } from './SearchAndFilters.types'
import { CurrentStateDB } from '@percent/partner-dashboard/services/grants/grantsService.types'

export function SearchAndFilters({ queryParams, setQueryParams }: Readonly<SearchAndFiltersProps>) {
  const { organisation, organisationSearch } = useOrganisationSearch({ queryParams, setQueryParams })
  const { t } = useTranslation()

  const handleFilterChange = (selectedValues: string[]) => {
    setQueryParams({ ...queryParams, currentState: selectedValues.length > 0 ? selectedValues : undefined })
  }

  return (
    <TableSearchAndFilters>
      <Flex w="100%" maw="480" mr={8}>
        <SearchInput
          name="organisation"
          value={organisation}
          handleClearValue={organisationSearch.handleClearOrganisationValue}
          onKeyDown={organisationSearch.handleKeyPress}
          onChange={organisationSearch.onOrganisationChange}
          onBlur={organisationSearch.validateOrganisation}
          placeholder={t('table.candidatesSearchPlaceholder')}
          status="default"
          inputSize="medium"
        />
        <Flex align="center" ml={8}>
          <FilterList
            label={t('grants.candidateState')}
            options={[
              { label: t('grants.candidateState.nominated'), value: CurrentStateDB.NOMINATED },
              { label: t('grants.candidateState.shortlisted'), value: CurrentStateDB.SHORTLISTED },
              { label: t('grants.candidateState.rejected'), value: CurrentStateDB.REJECTED }
            ]}
            selectedValues={queryParams.currentState || []}
            onChange={handleFilterChange}
            testId="candidate-state-filter"
          />
        </Flex>
      </Flex>
    </TableSearchAndFilters>
  )
}

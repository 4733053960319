import { Accordion, CardDashboard, Flex, Group, IllustratedMessage, Text, Tooltip } from '@percent/lemonade'
import styles from './GrantProgramCandidateReviewsCard.module.scss'
import generalStyles from '../GrantProgramCandidateDetailsContainer.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { goodstackPalette } from 'apps/partner-dashboard/src/app/theme/Theme'
import { OuterCard } from '../components/outerCard/OuterCard'
import CustomAccordion from '../components/customAccordion/CustomAccordion'
import { GrantProgramCandidateReviewsCardProps } from './GrantProgramCandidateReviewsCard.types'
const cx = classNames.bind(styles)

export function GrantProgramCandidateReviewsCard({
  reviews,
  onOpenReview,
  currentUserAccountId,
  canManageGrantReviews,
  showReviewButton
}: Readonly<GrantProgramCandidateReviewsCardProps>) {
  const { t } = useTranslation()

  const isReviewsEmpty = reviews.length === 0
  let sortedReviews = reviews

  if (canManageGrantReviews && reviews.length) {
    sortedReviews = reviews
      .filter(r => r.reviewer.accountId === currentUserAccountId)
      .concat(reviews.filter(r => r.reviewer.accountId !== currentUserAccountId))
  }

  return (
    <Flex direction="column" data-testid={`grant-program-candidate-reviews-page`} className={generalStyles.cardCtn}>
      {isReviewsEmpty ? (
        <CardDashboard data-testid="no-reviews-ctn">
          <IllustratedMessage
            title={t('grants.reviews.noReviews.title')}
            description={t('grants.reviews.noReviews.description')}
            illustration={'no-grant-candidates'}
            cta={
              showReviewButton
                ? {
                    label: t('grants.reviews.button.leaveReview'),
                    onPress: onOpenReview
                  }
                : undefined
            }
          />
        </CardDashboard>
      ) : (
        <CardDashboard title={t('grants.reviews.title')} data-testid="reviews-details">
          {sortedReviews.map((reviewData, index) => {
            const isOwnReview = canManageGrantReviews && reviewData.reviewer.accountId === currentUserAccountId
            return (
              <OuterCard
                key={reviewData.reviewer.accountId}
                dataTestId={`review-${index}`}
                customBgColour={isOwnReview ? 'info' : 'default'}
                badgeContent={
                  <div>
                    {isOwnReview
                      ? t('grants.reviews.badge.myReview')
                      : `${t('grants.reviews.badge.reviewBy')} ${reviewData.reviewer.email}`}
                  </div>
                }
                badgeVariant={isOwnReview ? 'informative' : 'default'}
              >
                <div
                  className={cx(
                    generalStyles.innerCard,
                    isOwnReview ? generalStyles.informativeBorder : generalStyles.defaultBorder
                  )}
                  data-testid="inner-card"
                >
                  {reviewData.review.map(question =>
                    question.questionType === 'score' ? (
                      <div
                        key={question.questionId}
                        className={cx(
                          generalStyles.innerCardSection,
                          isOwnReview ? generalStyles.informativeTopBorder : generalStyles.defaultTopBorder
                        )}
                      >
                        <Group className={styles.group}>
                          <div>
                            <Text fontWeight="medium" size="small" color={goodstackPalette.black}>
                              {question.questionLabel}
                            </Text>
                          </div>
                          <div>
                            <Flex className={cx(styles.scoreCtn, styles[`score${question.answer}`])}>
                              {question.answer}
                            </Flex>
                          </div>
                        </Group>
                      </div>
                    ) : (
                      <div
                        key={question.questionId}
                        className={cx(
                          generalStyles.innerCardSectionAccordion,
                          isOwnReview ? generalStyles.informativeTopBorder : generalStyles.defaultTopBorder
                        )}
                      >
                        <CustomAccordion>
                          <Accordion.Item value={`additional-question-${question.questionId}`}>
                            <Accordion.Control>{question.questionLabel}</Accordion.Control>
                            <Accordion.Panel>{question.answer}</Accordion.Panel>
                          </Accordion.Item>
                        </CustomAccordion>
                      </div>
                    )
                  )}
                </div>
              </OuterCard>
            )
          })}
        </CardDashboard>
      )}
    </Flex>
  )
}

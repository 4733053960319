import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useParams } from 'react-router-dom'

export const useGrantProgramCandidateDetails = () => {
  const { id: programId, candidateId } = useParams<{ id: string; candidateId: string }>()
  const { grantsService } = useServices()

  const [{ data: candidate, isLoading: isLoadingCandidate, error: candidateError }, { refresh: refreshCandidate }] =
    useQuery(grantsService.getGrantCandidate, { programId, candidateId })

  const [{ data: nominations, isLoading: isLoadingNominations, error: nominationsError }] = useQuery(
    grantsService.getGrantCandidateNominations,
    { programId, candidateId }
  )
  const [{ data: reviews, isLoading: isLoadingReviews, error: reviewsError }, { refresh: refreshReviews }] = useQuery(
    grantsService.getGrantCandidateReviews,
    { programId, candidateId }
  )

  return {
    data: {
      candidate,
      nominations,
      reviews
    },
    errors: {
      candidateError,
      nominationsError,
      reviewsError
    },
    refresh: {
      reviews: refreshReviews,
      candidate: refreshCandidate
    },
    isLoading: isLoadingCandidate || isLoadingNominations || isLoadingReviews
  }
}

import { ConfirmationModal, Modal, Spacer, AcknowledgeModal } from '@percent/lemonade'
import { Trans, useTranslation } from 'react-i18next'
import { AdminActionModalProps } from '../GrantProgramCandidateHeader.types'

export function RejectOrganisationModal({
  success,
  error,
  isLoading,
  organisationName,
  onSubmit,
  onClose,
  onSuccessClose
}: Readonly<AdminActionModalProps>) {
  const { t } = useTranslation()

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('grants.rejectOrganisationModal.success.title')}
      description={t('grants.rejectOrganisationModal.success.description', {
        organisation: organisationName
      })}
      buttonText={t('button.close')}
      handleClose={onSuccessClose}
      viewTestId="reject-success-modal"
      buttonTestId="close-reject-success-modal"
    />
  )

  const errorModal = error && (
    <AcknowledgeModal
      result="negative"
      title={t('grants.rejectOrganisationModal.error.title')}
      description={t('grants.rejectOrganisationModal.error.description', {
        organisation: organisationName
      })}
      buttonText={t('button.done')}
      handleClose={onClose}
      viewTestId="reject-error-modal"
      buttonTestId="close-reject-error-modal"
    />
  )

  return (
    <Modal open={true} onClose={onClose}>
      {successModal || errorModal || (
        <ConfirmationModal
          title={t('grants.rejectOrganisationModal.title')}
          loading={isLoading}
          primaryButtonText={t('button.reject')}
          primaryBtnTestId="reject-button"
          secondaryButtonText={t('button.cancel')}
          handleSubmit={onSubmit}
          handleClose={onClose}
        >
          <Trans
            i18nKey="grants.rejectOrganisationModal.description"
            components={{
              b: <b />,
              br: <Spacer axis="vertical" size={4} />
            }}
            values={{
              organisation: organisationName
            }}
          />
        </ConfirmationModal>
      )}
    </Modal>
  )
}

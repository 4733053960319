import React, { useMemo, useState } from 'react'

import { BreadcrumbsContext } from '../BreadcrumbsContext'
import { Breadcrumb } from '../BreadcrumbsContext.types'

export function BreadcrumbsContextController({ children }: Readonly<React.PropsWithChildren>) {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  const breadcrumbsContextValue = useMemo(
    () => ({
      breadcrumbs,
      setBreadcrumbs
    }),
    [breadcrumbs, setBreadcrumbs]
  )

  return <BreadcrumbsContext.Provider value={breadcrumbsContextValue}>{children}</BreadcrumbsContext.Provider>
}

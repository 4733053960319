import { GrantProgramCandidatesTable } from './grantProgramCandidatesTable/GrantProgramCandidatesTable'
import { useParams } from 'react-router-dom'
import { useDidMountEffect, useQuery, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useBreadcrumbs } from '../../layout/breadcrumbsContext/useBreadcrumbs/useBreadcrumbs'
import { RoutePath } from '../../layout/Routes'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { TableColumnSortDirection } from '@percent/lemonade'
import { ArrayParam, QueryParamConfig, StringParam, useQueryParams } from 'use-query-params'
import { SearchAndFilters } from './searchAndFilters/SearchAndFilters'
import { CandidatesTableQueryParams } from './searchAndFilters/SearchAndFilters.types'
import { CurrentStateDB } from '@percent/partner-dashboard/services/grants/grantsService.types'

const initialDirection: TableColumnSortDirection = 'DESC'

export function GrantProgramCandidatesContainer() {
  const [queryParams, setQueryParams] = useQueryParams<CandidatesTableQueryParams>({
    direction: StringParam as QueryParamConfig<TableColumnSortDirection | null | undefined>,
    searchTerm: StringParam,
    currentState: ArrayParam as QueryParamConfig<CurrentStateDB[] | null | undefined>
  })

  const { id: programId } = useParams<{ id: string }>()
  const { grantsService } = useServices()
  const { t } = useTranslation()

  const [direction, setDirection] = useState<TableColumnSortDirection>(
    (queryParams.direction as TableColumnSortDirection) || initialDirection
  )

  const [{ data: grantPrograms }] = useQuery(grantsService.getGrantProgram, { programId })
  const [
    { data: candidates, isLoading: isLoadingCandidates, totalResults: totalCandidates },
    { query, refresh: refreshCandidates, nextPage, previousPage }
  ] = useQueryList(grantsService.getGrantCandidates, { programId, ...queryParams })

  useDidMountEffect(() => {
    query({ programId, ...queryParams })
  }, [programId, query, queryParams])

  const handleRequestSort = () => {
    const newDirection = direction === 'ASC' ? 'DESC' : 'ASC'

    setDirection(newDirection)
    setQueryParams({ direction: newDirection })
  }

  useBreadcrumbs([
    {
      label: t('breadcrumb.grants'),
      path: RoutePath.GRANT_PROGRAMS
    },
    {
      label: grantPrograms?.name || ''
    }
  ])

  return (
    <div data-testid="grant-program-candidates-container">
      <GrantProgramCandidatesTable
        programName={grantPrograms?.name}
        data={candidates}
        programId={programId}
        isLoading={isLoadingCandidates}
        totalResults={totalCandidates ?? 0}
        refresh={refreshCandidates}
        nextPage={nextPage}
        previousPage={previousPage}
        sortDirection={direction}
        sortByColumn={'submittedAt'}
        handleRequestSort={handleRequestSort}
        filters={<SearchAndFilters queryParams={queryParams} setQueryParams={setQueryParams} />}
      />
    </div>
  )
}

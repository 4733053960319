import { ActionIcon, ActionIconProps } from '@mantine/core'
import { Button, Group, Icon, Text, Menu } from '@percent/lemonade'
import styled, { css } from 'styled-components'
import { SplitButtonProps } from './split-button.types'
import { colors } from '../button/button'
import { ButtonVariant } from '../button/button.types'

const SplitButtonWrapper = styled(Group)`
  display: flex;
  flex-wrap: nowrap;
  gap: 0;
`

const StyledButtonContainer = styled.div`
  ${({ theme }) => `
    button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: ${theme.fontSizes.buttonText};
      font-weight: ${theme.fontWeights.medium};
      border-right: 1px solid ${theme.colors.white};
    }
  `}
`

const MenuControl = styled(ActionIcon)<
  ActionIconProps & { variant: ButtonVariant }
>`
  ${({ theme, variant }) => {
    const variantStyles = colors[variant]({ theme, $loading: false })

    return css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: ${theme.sizes.l};

      ${variantStyles}
    `
  }}
`

export function SplitButton({
  menuItems,
  children,
  onSelect,
  ...buttonProps
}: Readonly<SplitButtonProps>) {
  const { variant = 'primary' } = buttonProps
  return (
    <SplitButtonWrapper>
      <StyledButtonContainer>
        <Button size="small" {...buttonProps}>
          {children}
        </Button>
      </StyledButtonContainer>
      <Menu
        title="Menu"
        sections={menuItems}
        onSelect={onSelect}
        customTriggerComponent={
          <MenuControl
            size={30}
            variant={variant}
            children={<Icon name="chevron-down" size={4} />}
            disabled={buttonProps.disabled}
            data-testid="split-button-menu-control"
          />
        }
      />
    </SplitButtonWrapper>
  )
}
